import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import  {customStyles} from "../../styles/customStyles"
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import TrainingTypeForm from '../../components/forms/trainingTypeF';
import TrainingTypeTable from '../../components/tables/trainingTypeT';
import { addTrainingType as addTrainingTypeService, getTrainingTypeById, updateTrainingType, deleteTrainingType, getTrainingType } from '../../services/trainingTypeS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddTrainingType = () => {
  const [trainingtypes, setTrainingTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTrainingTypes, setNewTrainingTypes] = useState({
    typeID: '',
    typeName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredTrainingTypes, setFilteredTrainingTypes] = useState(trainingtypes);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTrainingTypesID, setSelectedTrainingTypesID] = useState(null);

  useEffect(() => {
    const fetchedtrainingtype = async () => {
      try {
        const fetchedTrainingTypes = await getTrainingType();
        setTrainingTypes(fetchedTrainingTypes);
        setFilteredTrainingTypes(fetchedTrainingTypes); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching trainingtype:', error.response.data);
      }
    };

    fetchedtrainingtype();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTrainingTypes = async () => {
    try {
      const trainingtypePayload = {
        typeID: String(newTrainingTypes.typeID), // Ensure TrainingTypesID is a string
        typeName: newTrainingTypes.typeName,
        notes: newTrainingTypes.notes,
      };
  
      await addTrainingTypeService(trainingtypePayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding trainingtype:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add trainingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddTrainingTypesModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTrainingTypes({
      typeID: '',
      typeName: '',
      notes: '',
    });
  };

  const openEditTrainingTypesModal = async (typeID) => {
    try {
      const fetchedTrainingTypes = await getTrainingTypeById(String(typeID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTrainingTypesID(typeID);
      setNewTrainingTypes({
        ...fetchedTrainingTypes,
      });
    } catch (error) {
      console.error(`Error fetching trainingtype with ID ${typeID}:`, error.response.data);
    }
  };

  const updateExistingTrainingTypes = async () => {
    try {
      const trainingtypePayload = { ...newTrainingTypes };
      await updateTrainingType(selectedTrainingTypesID, trainingtypePayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error updating trainingtype with ID ${selectedTrainingTypesID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update trainingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingTrainingTypes = async (typeID) => {
    try {
      await deleteTrainingType(typeID);
      setUpdate((prev)=>!prev)
    } catch (error) {
      console.error(`Error deleting trainingtype with ID ${typeID}:`, error.response.data);
      alert(`Failed to delete trainingtype: ${error.response.data.title}`);
    }
  };

  const closeAddTrainingTypesModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTrainingTypeHandler = (typeID) => {
    if (window.confirm(`Are you sure you want to delete trainingtype with ID ${typeID}?`)) {
      deleteExistingTrainingTypes(typeID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterTrainingTypes(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterTrainingTypes(searchTerm, value);
    };
  
    const filterTrainingTypes = (searchTerm, column) => {
      const filtered = trainingtypes.filter((trainingtype) =>
        trainingtype[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredTrainingTypes(filtered);
    };
  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Training Types</h1>
      <div className="p-4">
        
        <div className="flex justify-between">
        <button
          onClick={openAddTrainingTypesModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
         <FaPlus />  <span>Training Types</span>   
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {trainingtypes.length > 0 ? (
                Object.keys(trainingtypes[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <TrainingTypeTable
          trainingtypes={filteredTrainingTypes}
          openEditModal={openEditTrainingTypesModal}
          deleteTrainingType={deleteTrainingTypeHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddTrainingTypesModal} contentLabel={editMode ? "Edit Training Types" : "Add trainingtype"}>
        <h2 className="text-xl mb-4">{editMode ? 'Edit Training Types' : 'Add Training Types'}</h2>
        <TrainingTypeForm 
          formValues={newTrainingTypes} 
          handleInputChange={handleInputChange} 
          handleDateChange={handleDateChange} 
          errors={errors} 
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingTrainingTypes : addNewTrainingTypes} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddTrainingTypesModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddTrainingType;
