import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FiSearch } from "react-icons/fi";

import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { customStyles } from "../../styles/customStyles";
import StatusForm from "../../components/forms/awardCStatusF";
import StatusTable from "../../components/tables/awardCStatusT";
import {
  addStatus as addStatusService,
  getStatusById,
  updateStatus,
  deleteStatus,
  getStatus,
} from "../../services/awardCStatusS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddStatus = () => {
  const [status, setStatus] = useState([]);
  const [update, setUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState({
    stateID: "",
    statusName: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});
  const [selectedColumn, setSelectedColumn] = useState("statusName");
  const [filteredStatus, setFilteredStatus] = useState(status);
  const [searchTerm, setSearchTerm] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedStatusID, setSelectedStatusID] = useState(null);

  useEffect(() => {
    const fetchedstatus = async () => {
      try {
        const fetchedStatus = await getStatus();
        setStatus(fetchedStatus);
        setFilteredStatus(fetchedStatus);
      } catch (error) {
        console.error("Error fetching status:", error.response.data);
      }
    };

    fetchedstatus();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterStatus = (searchTerm, column) => {
    const filtered = status?.filter((status) =>
      status[column].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStatus(filtered);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterStatus(searchTerm, value);
  };

  //handle search change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterStatus(value, selectedColumn);
  };

  const addNewStatus = async () => {
    try {
      const statusPayload = {
        statusID: String(newStatus.statusID), // Ensure StatusID is a string
        statusName: newStatus.statusName,
        notes: newStatus.notes,
      };

      console.log("New status Payload:", statusPayload);
      const addedstatus = await addStatusService(statusPayload);
       setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding status:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add status: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddStatusModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewStatus({
      statusID: "",
      statusName: "",
      notes: "",
    });
  };

  const openEditStatusModal = async (statusID) => {
    try {
      const fetchedStatus = await getStatusById(String(statusID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedStatusID(statusID);
      setNewStatus({
        ...fetchedStatus,
      });
    } catch (error) {
      console.error(
        `Error fetching status with ID ${statusID}:`,
        error.response.data
      );
    }
  };

  const updateExistingStatus = async () => {
    try {
      const statusPayload = { ...newStatus };

      console.log("Updated status Payload:", statusPayload);
      const updatedStatus = await updateStatus(selectedStatusID, statusPayload);
      setUpdate((update) => !update);
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        `Error updating status with ID ${selectedStatusID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update status: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingStatus = async (statusID) => {
    try {
      await deleteStatus(statusID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(
        `Error deleting status with ID ${statusID}:`,
        error.response.data
      );
      alert(`Failed to delete status: ${error.response.data.title}`);
    }
  };

  const closeAddStatusModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteStatusHandler = (statusID) => {
    if (
      window.confirm(
        `Are you sure you want to delete status with ID ${statusID}?`
      )
    ) {
      deleteExistingStatus(statusID);
    }
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Status</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddStatusModal}
            className="bg-blue-500 text-white px-1 rounded mb-4 mr-auto flex items-center gap-2">
            <FaPlus />
            <span>status</span>
          </button>

          <form className="px-2  rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {status.length > 0 ? (
                Object.keys(status[0])?.map((op) => (
                  <option value={op}>{splitWordFunc(op)}</option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>

        <StatusTable
          status={filteredStatus}
          openEditModal={openEditStatusModal}
          deleteStatus={deleteStatusHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddStatusModal}
        contentLabel={editMode ? "Edit Status" : "Add status"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit Status" : "Add Status"}
        </h2>
        <StatusForm
          formValues={newStatus}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingStatus : addNewStatus}
            className="bg-primary px-10 text-white p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddStatusModal}
            className="outline outline-1 outline-primary px-10 text-primary p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddStatus;
