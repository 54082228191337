import React from 'react';
import { Navigate } from 'react-router-dom';

// Helper function to check if the token is expired
const isTokenExpired = () => {
  const now = Date.now();
  const expiryTime = localStorage.getItem('tokenExpiry');
  return now > expiryTime;
};

// Function to check if the user has the required permission
const hasPermission = (permissions, requiredPermission) => {

  return permissions.some(permission => permission.name === requiredPermission);
};

const ProtectedRoute = ({ element: Component, requiredPermission, ...rest }) => {
  // Check if the token is valid
  if (isTokenExpired()) {
    return <Navigate to="/signin" />;
  }

  // Fetch token and user permissions from localStorage
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user')); // Assuming user object includes permissions array

  // Check if the user has the required permission
  if (!user || !user?.token || !hasPermission(user.user.role.permissions, requiredPermission)) {
    return <Navigate to="/signin" />;
  }

  // If everything is valid, render the component
  if(user.user.approvalStatus == 0){
    return <Navigate to="/approval" />;
  }
  
  return <Component {...rest} />;
};

export default ProtectedRoute;

