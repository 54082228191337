import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

const columns = [
  { Header: 'Username', accessor: 'username' },
  { Header: 'Names', accessor: 'name' },
  // { Header: 'Role', accessor: 'roleID' },
  // { Header: 'Gender', accessor: 'gender' },
  // { Header: 'Email', accessor: 'email' },
  // { Header: 'Phone No', accessor: 'phoneNo' },
  { Header: 'Approval Status', accessor: 'approvalStatus' },
];

const UserTable = ({ Users, openEditModal, deleteUser }) => {
  const renderRowActions = ({ username }) => (
    <div className='z-50'>
    <Dropdown user={true} username={username}  onEdit={()=>openEditModal(username)} onDelete={()=>deleteUser(username)}  />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={Users}
      renderRowActions={renderRowActions}
    />
  );
};

export default UserTable;
