import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown";

const ParticipantTable = ({ participants, openEditModal, deleteParticipant, openParticipantDetail }) => {
  
  const columns = [
    { 
      Header: 'Admin Number', 
      accessor: 'adminNumber',
      Cell: ({ value, row }) => (
        <button className="text-blue-500 underline" onClick={() => openParticipantDetail(row.original.adminNumber)}>
          {value}
        </button>
      )
    },
    { 
      Header: 'Name', accessor: 'name' },
    { Header: 'DOB', accessor: 'dob' },
    { Header: 'Gender', accessor: 'gender' },
    { Header: 'Age', accessor: 'age' },
    { Header: 'Religion', accessor: 'religion' },
    { Header: 'Ethnicity', accessor: 'ethnicity' },
    { Header: 'Nationality', accessor: 'nationality' },
    { Header: 'Phone No', accessor: 'phoneNumber' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Centre', accessor: 'institutionName' },
    { Header: 'Region', accessor: 'region' },
    { Header: 'County', accessor: 'county' },
    { Header: 'Sub-County', accessor: 'subCounty' },
    { Header: 'Guardian Name', accessor: 'guardianName' },
    { Header: 'Guardian Contact', accessor: 'guardianContact' },
    { Header: 'Emerg. Contact', accessor: 'emergencyCName' },
    { Header: 'Emerg. Number', accessor: 'emergencyCNumber' },
    { Header: 'Emerg. Relation', accessor: 'emergencyCRelation' },
    { Header: 'Status', accessor: 'paymentStatus' },
    { Header: 'Marginalised', accessor: 'marginalised' },
    { Header: 'At Risk?', accessor: 'atRisk' },
    { Header: 'Notes', accessor: 'notes' },
  ];

  const renderRowActions = ({ adminNumber }) => (
    <div className='z-50'>
      <Dropdown onEdit={() => openEditModal(adminNumber)} onDelete={() => deleteParticipant(adminNumber)} />
    </div>
  );

  return (
    <Table
      columns={columns}
      data={participants}
      renderRowActions={renderRowActions}
    />
  );
};

export default ParticipantTable;
