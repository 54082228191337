import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import AjApprovalForm from '../../components/forms/ajApprovalF';
import AjApprovalTable from '../../components/tables/ajApprovalT';
import { addAjApproval, getAjApprovalById, updateAjApproval, deleteAjApproval, getAjApproval } from '../../services/ajsApprovalS';
import AjApprovalModal from '../activities/AjApprovalModal';
import Layout from '../../components/Layout/layout';
Modal.setAppElement('#root');

const AddAjApproval = () => {
  const [AjApproval, setAjApprovals] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [newAjApproval, setNewAjApproval] = useState({

    awardCentre: '',
    awardLeader: '',
    region: '',
    activityName: '',
    activityDate: '',
    assessors: '',
    assessors2: '',
    assessors3: '',
    participantsNo: '',
    uploadForm: null,
    approval: false,
    consent: false,
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredAjApprovals, setFilteredAjApprovals] = useState(AjApproval);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedAjApprovalId, setSelectedAjApprovalId] = useState(null);

  useEffect(() => {
    const fetchAjApprovals = async () => {
      try {
        const fetchedAjApprovals = await getAjApproval();
        setAjApprovals(fetchedAjApprovals);
        setFilteredAjApprovals(fetchedAjApprovals);
      } catch (error) {
        console.error('Error fetching AjApproval:', error.response.data);
      }
    };

    fetchAjApprovals();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAjApproval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewAjApproval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const openApprovalModal = (application) => {
    setSelectedApplication(application);
    setIsApprovalModalOpen(true);

  };

  const handleApprove = async (id, assessors) => {
    try {
      // Ensure assessors are assigned before approving
      if (!assessors || assessors.length === 0) {
        alert('You must assign at least one assessor before approving.');
        return; // Stop the approval process if no assessors are assigned
      }
      // Pass the entire application object to update the approval
      const application = AjApproval.find(app => app.id === id);
      const updatedApplication = { ...application, approval: true, assessors };
  
      await updateAjApproval(id, updatedApplication); // Pass entire updated application
      setUpdate(prev => !prev); // Force a refresh after approval
      setIsApprovalModalOpen(false); // Close modal
  
      // Update local state to reflect the change
      setAjApprovals(prevApprovals =>
        prevApprovals.map(app =>
          app.id === id ? updatedApplication : app
        )
      );
      setFilteredAjApprovals(prevFiltered =>
        prevFiltered.map(app =>
          app.id === id ? updatedApplication : app
        )
      );
    } catch (error) {
  
    }
  };

  const handleReject = async (id) => {
    try {
      // Find the application by ID from the current ajApprovals state
      const application = AjApproval.find(app => app.id === id);
      const updatedApplication = { ...application, approval: false }; // Update approval to false

      await updateAjApproval(id, updatedApplication);
      setUpdate(prev => !prev);  
      setIsApprovalModalOpen(false);  
      setAjApprovals(prevApprovals => 
        prevApprovals.map(app => 
          app.id === id ? updatedApplication : app
        )
      );
      setFilteredAjApprovals(prevFiltered => 
        prevFiltered.map(app => 
          app.id === id ? updatedApplication : app
        )
      );
    } catch (error) {
      console.error('Error rejecting application:', error);
      let errorMessage = 'Failed to reject the application. Please try again.';
      if (error.response && error.response.data && error.response.data.errors) {
        errorMessage += ' Error details: ' + JSON.stringify(error.response.data.errors);
      }
      alert(errorMessage);
    }
  };
  

  const addNewAjApproval = async () => {
    try {
      const AjApprovalPayload = { ...newAjApproval };
      await addAjApproval(AjApprovalPayload);
      setIsModalOpen(false);
      setUpdate(prev => !prev)
      setErrors({});
    } catch (error) {
      console.error('Error adding AjApproval:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add AjApproval: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddAjApprovalModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewAjApproval({
      awardCentre: '',
      awardLeader: '',
      region: '',
      activityName: '',
      activityDate: '',
      assessors: '',
      assessors2: '',
      assessors3: '',
      participantsNo: '',
      uploadForm: null,
      approval: false,
      consent: false,
      notes: '',
    });
  };

  const openEditAjApprovalModal = async (ID) => {
    try {
      console.log('Fetching AjApproval with ID:', ID);
      const fetchedAjApproval = await getAjApprovalById(ID);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedAjApprovalId(ID);
      setNewAjApproval({
        ...fetchedAjApproval,
        startDate: fetchedAjApproval.startDate,
      });
    } catch (error) {
      console.error(`Error fetching AjApproval with ID ${ID}:`, error.response.data);
    }
  };

  const updateExistingAjApproval = async () => {
    try {
      const AjApprovalPayload = { ...newAjApproval };

      await updateAjApproval(selectedAjApprovalId, AjApprovalPayload);
      setUpdate(prev => !prev)

      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating AjApproval with ID ${selectedAjApprovalId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update AjApproval: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingAjApproval = async (ID) => {
    try {
      await deleteAjApproval(ID);
      setUpdate(prev => !prev)
    } catch (error) {
      console.error(`Error deleting AjApproval with ID ${ID}:`, error.response.data);
      alert(`Failed to delete AjApproval: ${error.response.data.title}`);
    }
  };

  const closeAddAjApprovalModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteAjApprovalHandler = (ID) => {
    if (window.confirm(`Are you sure you want to delete AjApproval with ID ${ID}?`)) {
      deleteExistingAjApproval(ID);
    }
  };

  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterAjApprovals(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterAjApprovals(searchTerm, value);
  };

  const filterAjApprovals = (searchTerm, column) => {
    const filtered = AjApproval.filter((AjApproval) =>
      AjApproval[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredAjApprovals(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Adventurous Journey Approval</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddAjApprovalModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
          >
            <FaPlus />  <span>AJ Approval</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {AjApproval.length > 0 ? (
                Object.keys(AjApproval[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <AjApprovalTable
          AjApproval={filteredAjApprovals}
          openEditModal={openEditAjApprovalModal}
          deleteAjApproval={deleteAjApprovalHandler}
          openApprovalModal={openApprovalModal}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddAjApprovalModal} contentLabel={editMode ? "Edit AjApproval" : "Add AjApproval"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit Approval' : 'Add Approval'}</h2>
        <AjApprovalForm
          formValues={newAjApproval}
          setFormValues={setNewAjApproval}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingAjApproval : addNewAjApproval} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddAjApprovalModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
      <AjApprovalModal
        isOpen={isApprovalModalOpen}
        onClose={() => setIsApprovalModalOpen(false)}
        application={selectedApplication}
        onApprove={handleApprove}
        onReject={handleReject}
      />
    </Layout>
  );
};

export default AddAjApproval;