import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import HelperTypesForm from "../../components/forms/helperTypeF";
import HelperTypesTable from "../../components/tables/helperTypeT";
import {
  addHelperTypes as addHelperTypesService,
  getHelperTypesById,
  updateHelperTypes,
  deleteHelperTypes,
  getHelperTypes,
} from "../../services/helperTypeS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddHelperTypes = () => {
  const [helpertypes, setHelperTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newHelperTypes, setNewHelperTypes] = useState({
    typeID: "",
    typeName: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredHelperTypes, setFilteredHelperTypes] = useState(helpertypes);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedHelperTypesID, setSelectedHelperTypesID] = useState(null);

  useEffect(() => {
    const fetchedhelpertypes = async () => {
      try {
        const fetchedHelperTypes = await getHelperTypes();
        setHelperTypes(fetchedHelperTypes);
        setFilteredHelperTypes(fetchedHelperTypes);
      } catch (error) {
        console.error("Error fetching helpertypes:", error.response.data);
      }
    };

    fetchedhelpertypes();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewHelperTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewHelperTypes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewHelperTypes = async () => {
    try {
      const helpertypesPayload = {
        typeID: String(newHelperTypes.typeID), // Ensure HelperTypesID is a string
        typeName: newHelperTypes.typeName,
        notes: newHelperTypes.notes,
      };

      console.log("New helpertypes Payload:", helpertypesPayload);
      const addedhelpertypes = await addHelperTypesService(helpertypesPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding helpertypes:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add helpertypes: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddHelperTypesModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewHelperTypes({
      typeID: "",
      typeName: "",
      notes: "",
    });
  };

  const openEditHelperTypesModal = async (typeID) => {
    try {
      const fetchedHelperTypes = await getHelperTypesById(
        String(typeID)
      );
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedHelperTypesID(typeID);
      setNewHelperTypes({
        ...fetchedHelperTypes,
      });
    } catch (error) {
      console.error(
        `Error fetching helpertypes with ID ${typeID}:`,
        error.response.data
      );
    }
  };

  const updateExistingHelperTypes = async () => {
    try {
      const helpertypesPayload = { ...newHelperTypes };

      console.log("Updated helpertypes Payload:", helpertypesPayload);

      const updatedHelperTypes = await updateHelperTypes(
        selectedHelperTypesID,
        helpertypesPayload
      );
      setHelperTypes((prev) =>
        prev.map((inst) =>
          inst.typeID === selectedHelperTypesID ? updatedHelperTypes : inst
        )
      );
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        `Error updating helpertypes with ID ${selectedHelperTypesID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update helpertypes: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingHelperTypes = async (typeID) => {
    try {
      await deleteHelperTypes(typeID);
      setUpdate((prev)=>!prev)
    } catch (error) {
      console.error(
        `Error deleting helpertypes with ID ${typeID}:`,
        error.response.data
      );
      alert(`Failed to delete helpertypes: ${error.response.data.title}`);
    }
  };

  const closeAddHelperTypesModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteHelperTypesHandler = (typeID) => {
    if (
      window.confirm(
        `Are you sure you want to delete helpertypes with ID ${typeID}?`
      )
    ) {
      deleteExistingHelperTypes(typeID);
    }
  };
   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterHelpertypes(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterHelpertypes(searchTerm, value);
  };

  const filterHelpertypes = (searchTerm, column) => {
    const filtered = helpertypes.filter((helpertype) =>
      helpertype[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredHelperTypes(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Helper Types</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddHelperTypesModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2">
            <FaPlus /> <span>Helper Types</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {helpertypes.length > 0 ? (
                Object.keys(helpertypes[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <HelperTypesTable
          helpertypes={filteredHelperTypes}
          openEditModal={openEditHelperTypesModal}
          deleteHelperTypes={deleteHelperTypesHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddHelperTypesModal}
        contentLabel={editMode ? "Edit Helper Types" : "Add helpertypes"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit Helper Types" : "Add Helper Types"}
        </h2>
        <HelperTypesForm
          formValues={newHelperTypes}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingHelperTypes : addNewHelperTypes}
            className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddHelperTypesModal}
            className="outline outline-1 outline-primary px-5 text-primary p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddHelperTypes;
