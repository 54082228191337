import './App.css';
import React, { useEffect } from 'react'; 

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { logAction } from './utils/logger';
import CalendarPage from './pages/calendar/calendar';
import Profile from "./pages/profile/profile"
import Settings from "./pages/settings"
import AddInstitution from './pages/awardcenter/awardCenter';
import AddStatus from './pages/awardcenter/awardCStatus';
import AddStages from './pages/awardcenter/awardCStages';
import AddCenter from './pages/awardcenter/awardCTypes'
import AddParticipant from './pages/participants/participant';
import AddAwards from './pages/participants/participantAwards'
import AddLevels from './pages/participants/participantLevels';
import Approval from "./pages/approval/approval"
import AddParticipantProject from './pages/participants/participantProjects'
import AddParticipantActivity from './pages/activities/activities'
import AddAjApproval from './pages/activities/ajsApproval'
import AddAjReporting from './pages/activities/ajsReporting'
import AddHelper from './pages/helpers/helper';
import AddHelperTypes from './pages/helpers/helperType';
import DashboardStatsGrid from './pages/dashboard/dashboard';
import AddDonor from './pages/project/donors';
import AddProject from './pages/project/project';
import AddProjectStatus from './pages/project/projectStatus';
import AddTestimonial from './pages/project/testimonials';
import AddProgram from './pages/program/program';
import AddTraining from './pages/training/training';
import AddTrainingType from './pages/training/trainingType';
import AddTrainingLevel from './pages/training/trainingLevel';
import AddTrainingCategory from './pages/training/trainingCategory';
import AddBudget from './pages/financials/budget';
import AddFundingType from './pages/financials/fundingtype';
import AddPartnership from './pages/partnership/partnership';
import AddPartnerTypes from './pages/partnership/partnertype';
import AddFeedback from './pages/research/feedback';
import AwardCenters from './pages/reports/AwardCentersR';
import ParticipantsR from './pages/reports/ParticipantsR';
import AjsR from './pages/reports/AjR';
import ProgramsR from './pages/reports/ProgramsR';
import ProjectsR from './pages/reports/ProjectsR';
import TrainingR from './pages/reports/TrainingsR';
import ForgotPassword from './pages/forgotPassword/forgotPassword';
import ChangePassword from './pages/changePassword/changPassword';
import SignIn from './pages/signin';
import Signup from './pages/signup'
import Users from "./pages/users"
import GeneralSettings from './pages/settings/GeneralSettings';
import NotificationSettings from './pages/settings/NotificationSettings';
import IntegrationSettings from './pages/settings/IntegrationSettings';
import SystemLogs from './pages/settings/SystemLogs';
import UserRoles from './pages/settings/UserRoles';
import PermissionRoles from "./pages/settings/UserRoles/Roles/PermissionRoles"
import Synchronization from './pages/settings/Synchronization';
import ProtectedRoute from './services/ProtectedRoute/protectedRoute';
import UnprotectedRoute from './services/UnprotectedRoute/unprotectedRoute';

function App() {
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'hidden') {
        await logAction('Page Unload', 'N/A', 'User navigated away from the page');
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<UnprotectedRoute element={SignIn} />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signin" element={<UnprotectedRoute element={SignIn} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<ProtectedRoute element={DashboardStatsGrid} requiredPermission="Access to Dashboard" />} />
          <Route path="/approval" element={<Approval />} />
          <Route path="/profile" element={<ProtectedRoute element={Profile} requiredPermission="Access to Dashboard" />} />
          <Route path="/settings" element={<ProtectedRoute element={Settings} requiredPermission="Access to settings" />} >
          <Route path='company' element={<ProtectedRoute element={GeneralSettings} requiredPermission="Access to settings" />} />
          <Route path='notification' element={<ProtectedRoute element={NotificationSettings} requiredPermission="Access to settings" />} />
          <Route path='integrations' element={<ProtectedRoute element={IntegrationSettings} requiredPermission="Access to settings" />} />
          <Route path='system-logs' element={<ProtectedRoute element={SystemLogs} requiredPermission="Access to settings" />} requiredPermission="Access to settings" />
          <Route path='synchronization' element={<ProtectedRoute element={Synchronization} requiredPermission="Access to settings" />} />
          <Route path="users">
              <Route index element={<ProtectedRoute element={UserRoles} requiredPermission="Access to settings" />} />
              <Route path=":id"  element={<ProtectedRoute element={PermissionRoles} requiredPermission="Access to settings" />} />
            </Route>
          </Route>
          <Route path="/calendar" element={<ProtectedRoute element={CalendarPage}/>} requiredPermission="Access to calendar" />
          <Route path="/institutions/addInstitutions" element={<ProtectedRoute element={AddInstitution} requiredPermission="Access to Dashboard"/>} />
          <Route path='/institutions/institutionStages' element={<ProtectedRoute element={AddStages} requiredPermission="Access to stages"/>} />
          <Route path='/institutions/institutionTypes' element={<ProtectedRoute element={AddCenter}  requiredPermission="Access to type" />} />
          <Route path='/institutions/institutionStatus' element={<ProtectedRoute element={AddStatus} requiredPermission="Access to status" />} />
          <Route path="/participants/participant" element={<ProtectedRoute element={AddParticipant} requiredPermission="Access to participants" />} />
          <Route path="/participants/participant-award" element={<ProtectedRoute element={AddAwards} requiredPermission="Access to awards" />} />
          <Route path="/participants/participant-projects" element={<ProtectedRoute element={AddParticipantProject} requiredPermission="Access to projects" />} />
          <Route path="/participants/participantActivities" element={<ProtectedRoute element={AddParticipantActivity} requiredPermission="Access to Adventurous Journey activities" />} />
          <Route path="/participants/participantLevels" element={<ProtectedRoute element={AddLevels} requiredPermission="Access to levels" />} />
          <Route path="/ajs/ajapproval" element={<ProtectedRoute element={AddAjApproval} requiredPermission="Access to Adventurous Journey" />}  />
          <Route path="/ajs/ajreporting" element={<ProtectedRoute element={AddAjReporting} requiredPermission="Access to Adventurous Journey reportings" />} />
          <Route path="/helpers/helper" element={<ProtectedRoute element={AddHelper} requiredPermission="Access to Adult Helpers" />} />
          <Route path="/helpers/helperType" element={<ProtectedRoute element={AddHelperTypes} requiredPermission="Access to Helpers types" />} />
          <Route path="/partnership/partnership" element={<ProtectedRoute element={AddPartnership} requiredPermission="Access to Partnership" />} />
          <Route path="/partnership/partnertype" element={<ProtectedRoute element={AddPartnerTypes} requiredPermission="Access to Partner Type" />} />
          <Route path="/project/project" element={<ProtectedRoute element={AddProject} requiredPermission="Access to projects" />} />
          <Route path="/project/projectstatus" element={<ProtectedRoute element={AddProjectStatus} requiredPermission="Access to project status" />} />
          <Route path="/project/donors" element={<ProtectedRoute element={AddDonor} requiredPermission="Access to donors" />} />
          <Route path="/project/testimonials" element={<ProtectedRoute element={AddTestimonial} requiredPermission="Access to project testimonials" />} />
          <Route path="/program/program" element={<ProtectedRoute element={AddProgram} requiredPermission="Access to programs" />} />
          <Route path="/financials/budget" element={<ProtectedRoute element={AddBudget} requiredPermission="Access to Financial" />} />
          <Route path="/financials/fundingtype" element={<ProtectedRoute element={AddFundingType} requiredPermission="Access to funding type" />} />
          <Route path="/training/training" element={<ProtectedRoute element={AddTraining} requiredPermission="Access to Training" />} />
          <Route path="/training/trainingType" element={<ProtectedRoute element={AddTrainingType} requiredPermission="Access to Training types" />} />
          <Route path="/training/trainingLevel" element={<ProtectedRoute element={AddTrainingLevel} requiredPermission="Access to Training level" />} />
          <Route path="/training/trainingCategory" element={<ProtectedRoute element={AddTrainingCategory} requiredPermission="Access to Training category" />} />
          <Route path="/reports/AwardCentersR" element={<ProtectedRoute element={AwardCenters} requiredPermission="Access to Center report" />} />
          <Route path="/reports/ParticipantsR" element={<ProtectedRoute element={ParticipantsR} requiredPermission="Access to participants report" />} />
          <Route path="/reports/AjR" element={<ProtectedRoute element={AjsR} requiredPermission="Access to Ajs report" />} />
          <Route path="/reports/ProgramsR" element={<ProtectedRoute element={ProgramsR} requiredPermission="Access to programs report" />} />
          <Route path="/reports/ProjectsR" element={<ProtectedRoute element={ProjectsR} requiredPermission="Access to projects report" />} />
          <Route path="/reports/TrainingsR" element={<ProtectedRoute element={TrainingR} requiredPermission="Access to Trainings report" />} />
          <Route path="/users" element={<ProtectedRoute element={Users} requiredPermission="Access to settings" />} />
          <Route path="/research/feedback" element={<ProtectedRoute element={AddFeedback} requiredPermission="Access to feedback" />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;