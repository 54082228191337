import React from 'react';
import Table from '../common/Table'; // Make sure the Table component path is correct
import { format } from 'date-fns'; 
const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Username', accessor: 'username' },
  { Header: 'Action Type', accessor: 'actionType' },
  { Header: 'Entity Name', accessor: 'entityName' },
  { Header: 'IP Address', accessor: 'ipAddress' },
  { Header: 'Browser', accessor: 'browser' },
  {
    Header: 'Timestamp',
    accessor: 'timeStamp',
    Cell: ({ value }) => {

      // Ensure value is in a format that can be parsed by Date
      const date = new Date(value);
      const formattedDate = format(date, 'MM/dd/yyyy'); // Format date
      const formattedTime = format(date, 'hh:mm a'); // Format time in AM/PM
      return `${formattedDate} ${formattedTime}`;
    }
  },
  { Header: 'Description', accessor: 'description' },
];

const SystemLogsTable = ({ logs }) => {
  return (
    <Table
      columns={columns}
      data={logs}
    />
  );
};

export default SystemLogsTable;
