import React, { useState, useEffect } from 'react';
import {customStyles} from "../../styles/customStyles"
import { FaPlus } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { FaFilter } from 'react-icons/fa';
import { splitWordFunc } from '../../utils/splitWordFunc';
import InstitutionForm from '../../components/forms/awardCenterF';
import InstitutionTable from '../../components/tables/awardCenterT';
import { addInstitution as addInstitutionService, getInstitutionById, updateInstitution, deleteInstitution, getInstitutions } from '../../services/awardCenterS';
import Layout from '../../components/Layout/layout';
import Modal from 'react-modal';
import ViewComponent from '../../components/ViewComponent';

const AddInstitution = () => {
  const [institutions, setInstitutions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewModal,setViewModal] = useState(false)
  const [viewData,setViewData] = useState({})
  const [selectedColumn, setSelectedColumn] = useState("");
  const [update,setUpdate] = useState(false)
  const [editableInstitution,setEditableInstitution] = useState({})
  const [filteredInstitutions, setFilteredInstitutions] = useState(institutions);
  const [searchTerm, setSearchTerm] = useState('');
 
  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        const fetchedInstitutions = await getInstitutions();
        setInstitutions(fetchedInstitutions);
        setFilteredInstitutions(fetchedInstitutions)
      } catch (error) {
        console.error('Error fetching institutions:', error.response);
      }
    };
    fetchInstitutions();
  }, [update]);

 //Generate ID function
  const generateInstitutionID = () => {
    if (institutions.length > 0) {
      const lastInstitution = institutions[institutions.length - 1];
      const lastID = parseInt(lastInstitution.institutionID.split('-')[2], 10);
     
      if (!isNaN(lastID)) {
        const newID = `PAK-AC-${String(lastID + 1).padStart(3, '0')}`;
        return newID;
      }
    }
    // Return the first ID if there are no institutions or if parsing failed
    return 'PAK-AC-001';
  };

  const openEditInstitutionModal = async (institutionID) => {
    try {
      const fetchedInstitution = await getInstitutionById(institutionID);
      setEditableInstitution(fetchedInstitution)
      setEditMode(true);
      setIsModalOpen(true);
    } catch (error) {
      console.error(`Error fetching institution with ID ${institutionID}:`, error.response.data);
    }
  };

  const openViewModal = async (institutionID) => {
    try {
      const fetchedInstitution = await getInstitutionById(institutionID);
      setViewModal(true);
      setViewData(fetchedInstitution)
    } catch (error) {
      console.error(`Error fetching institution with ID ${institutionID}:`, error.response.data);
    }
  };

  //close view modal
  const handleViewClose = ()=> {
    setViewModal(false)
  }

  const deleteExistingInstitution = async (institutionId) => {
    try {
      await deleteInstitution(institutionId);
     setUpdate(prev=>!prev)
     setIsModalOpen(false);
    } catch (error) {
      console.error(`Error deleting institution with ID ${institutionId}:`, error.response.data);
      alert(`Failed to delete institution: ${error.response.data.title}`);
    }
  };

  const closeAddInstitutionModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setUpdate(prev=>!prev)
  };

  const deleteInstitutionHandler = (institutionId) => {
    if (window.confirm(`Are you sure you want to delete institution with ID ${institutionId}?`)) {
      deleteExistingInstitution(institutionId);
    }
  };

//new changes
const filterInstitutions = (searchTerm, column) => {
  const filtered = institutions?.filter(institution =>
    institution[column].toLowerCase().includes(searchTerm.toLowerCase())
  );
  setFilteredInstitutions(filtered);
};
const handleFilterParameterChange = (event) => {
  const value = event.target.value;
  setSelectedColumn(value);
  filterInstitutions(searchTerm, value);
};

//handle search change
const handleSearchChange = (event) => {
  const value = event.target.value;
  setSearchTerm(value);
  filterInstitutions(value, selectedColumn);
};

  return (
    <Layout>
      <div className="">
        <h1 className="text-2xl font-[600] ml-3 mb-4">Award Center</h1>
        <div className="p-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
  {/* Add Award Center Button */}
  <button
    onClick={() => {
      setIsModalOpen(true);
      setEditableInstitution({});
    }}
    className="bg-blue-500 text-white py-2 px-4 flex items-center gap-2 rounded-md shadow hover:bg-blue-600 transition-all duration-300 ease-in-out"
  >
    <FaPlus /> <span>Award Center</span>
  </button>

{/* Filter Form */}
<form className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto">

  {/* Filter Select */}
  <div className="relative flex items-center w-full md:w-auto">
    <select
      className="p-3 pr-10 py-2 text-base border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 shadow-sm"
      value={selectedColumn}
      onChange={handleFilterParameterChange}
    >
      {institutions.length > 0
        ? Object.keys(institutions[0]).map((op, index) => (
            <option key={index} value={op}>
              {splitWordFunc(op)}
            </option>
          ))
        : <option>No filter property</option>}
    </select>

    <button className="absolute right-3 text-gray-400 hover:text-gray-600 text-lg">
      <FaFilter />
    </button>
  </div>

  {/* Search Input */}
  <div className="relative flex items-center w-full md:w-auto">
    <input
      type="text"
      placeholder="Search..."
      onChange={handleSearchChange}
      className="rounded-full border  py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-indigo-500  bg-gray-100 text-gray-700 placeholder-gray-500 shadow-sm"
    />
    <button className="absolute right-3 text-gray-400 hover:text-gray-600 text-lg">
      <FiSearch />
    </button>
  </div>

</form>

</div>

          <InstitutionTable
            openViewModal={openViewModal}
            institutions={filteredInstitutions}
            openEditModal={openEditInstitutionModal}
            deleteInstitution={deleteInstitutionHandler}
          />
        </div>


        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeAddInstitutionModal}
          contentLabel={editMode ? "Edit Institution" : "Add Institution"}
          style={customStyles}>
          <h2 className="subtitle1 mb-4">
            {editMode ? "Edit Award Center" : "Add Award Center "}
          </h2>
          <InstitutionForm
            editMode={editMode}
            formValues={editMode ? editableInstitution : { institutionID: generateInstitutionID() }}
            closeAddInstitutionModal={closeAddInstitutionModal}
          />
        </Modal>
        <ViewComponent handleClose={handleViewClose}  isModalOpen={viewModal} inputConfig={viewData} moduleName={"Award Center"}/>
      </div>
    </Layout>
  );
};


export default AddInstitution;