import React, { useState, useEffect } from 'react';
import Input from '../common/Input';
import styles from '../../styles/modal.module.css';
import { getInstitutions } from '../../services/awardCenterS'
import moment from 'moment'


const AjApprovalForm = ({ formValues, setFormValues, handleInputChange, handleDateChange, errors }) => {
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleCheckboxChange = (e) => {
    setFormValues({
      ...formValues,
      consent: e.target.checked,
    });
  };

  //Fetch Institutions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedInstitutions = await getInstitutions();
        setInstitutions(fetchedInstitutions);
      } catch (error) {
        console.error('Error fetching institutions:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatDate = (date) => {
    return date instanceof Date 
      ? date.toISOString().split('T')[0]
      : '';
  };

  return (
    <form className={styles.form}>
      <div className="space-y-4">

        <div>
          <label htmlFor="awardCentre">Award Center:</label>
          {loading ? (
            <p>Loading award centers...</p>
          ) : (
            <select
              name="awardCentre"
              value={formValues.awardCentre}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Award Center</option>
              {institutions.map((institution) => (
                <option key={institution.institutionID} value={institution.awardCentre}>
                  {institution.institutionName}
                </option>
              ))}
            </select>
          )}
          {errors.awardCentre && <p className="text-red-500">{errors.institutionID[0]}</p>}
        </div>

        <div>
          <label htmlFor="region">Region:</label>
          <select className="w-full p-2 border border-gray-300 rounded"
            name="region"
            value={formValues.region}
            onChange={handleInputChange}
          >
            <option value="" disabled>Select a region</option>
            <option value="Central">Central</option>
            <option value="Coast">Coast</option>
            <option value="Lower Eastern">Lower Eastern</option>
            <option value="Nairobi">Nairobi</option>
            <option value="North Eastern">North Eastern</option>
            <option value="North Rift">North Rift</option>
            <option value="Nyanza">Nyanza</option>
            <option value="South Rift">South Rift</option>
            <option value="Upper Eastern">Upper Eastern</option>
            <option value="Western">Western</option>
          </select>
          {errors.region && <p className="text-red-500">{errors.region[0]}</p>}
        </div>

        <div>
          <label htmlFor="awardLeader">Award Leader:</label>
          <Input
            name="awardLeader"
            placeholder="Award Leader"
            value={formValues.awardLeader}
            onChange={handleInputChange}
          />
          {errors.awardLeader && <p className="text-red-500">{errors.awardLeader[0]}</p>}
        </div>

        <div>
          <label htmlFor="activityName">Activity Name:</label>
          <Input
            name="activityName"
            placeholder="Activity Name"
            value={formValues.activityName}
            onChange={handleInputChange}
          />
          {errors.activityName && <p className="text-red-500">{errors.activityName[0]}</p>}
        </div>

        <div>
          <label htmlFor="activityDate">Activity Date:</label>
          <Input
            type="date"
            name="activityDate"
            value={formValues.activityDate}
            onChange={handleDateChange}
          />
          {errors.activityDate && <p className="text-red-500">{errors.activityDate[0]}</p>}
        </div>

        <div>
      <label htmlFor="applyDate">Apply Date:</label>
      <Input
        type="date"
        name="applyDate"
        value={formatDate(formValues.applyDate ? new Date(formValues.applyDate) : new Date())}
        onChange={handleDateChange}
      />
      {errors.applyDate && <p className="text-red-500">{errors.applyDate[0]}</p>}
    </div>


        <div>
          <label htmlFor="participantsNo">No. of Participants:</label>
          <Input
            type="number"
            name="participantsNo"
            placeholder="Participants No"
            value={formValues.participantsNo}
            onChange={handleInputChange}
          />
          {errors.participantsNo && <p className="text-red-500">{errors.participantsNo[0]}</p>}
        </div>

        <div>
          <label htmlFor="uploadForm">Upload Form:</label>
          <Input
            type="file"
            name="uploadForm"
            onChange={handleInputChange}
          />
          {errors.uploadForm && <p className="text-red-500">{errors.uploadForm[0]}</p>}
        </div>

        <div>
          <label htmlFor="consent">Consent:</label>
          <input
            type="checkbox"
            name="consent"
            checked={formValues.consent}
            onChange={handleCheckboxChange}
          />
          {errors.consent && <p className="text-red-500">{errors.consent[0]}</p>}
        </div>


        <div>
          <label htmlFor="notes">Notes:</label>
          <Input
            name="notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.notes[0]}</p>}
        </div>
      </div>
    </form>
  );
};

export default AjApprovalForm;
