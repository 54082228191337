import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import FeedbackForm from '../../components/forms/feedbackF';
import FeedbackTable from '../../components/tables/feedbackT';
import { addFeedback as addFeedbackService, getFeedbackById, updateFeedback, deleteFeedback, getFeedback } from '../../services/feedbackS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');
const AddFeedback = () => {
  const [feedback, setFeedbacks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFeedback, setNewFeedback] = useState({ 
      
      respondentID: '',
      titleName: '',
      description: '',
      respondentType: '',
      phoneNo: '',
      respondentEmail: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("titleName");
  const [filteredFeedbacks, setFilteredFeedbacks] = useState(feedback);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const fetchedFeedbacks = await getFeedback();
        setFeedbacks(fetchedFeedbacks);
        setFilteredFeedbacks(fetchedFeedbacks); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching Feedbacks:', error.response.data);
      }
    };

    fetchFeedbacks();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFeedback((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewFeedback((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewFeedback = async () => {
    try {
      const feedbackPayload = { ...newFeedback };
      await addFeedbackService(feedbackPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error('Error adding respondent:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add respondent: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddFeedbackModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewFeedback({
      respondentID: '',
      titleName: '',
      description: '',
      respondentType: '',
      phoneNo: '',
      respondentEmail: '',
    });
  };

  const openEditFeedbackModal = async (respondentID) => {
    try {
      console.log('Fetching respondent with ID:', respondentID);
      const fetchedFeedback = await getFeedbackById(respondentID);
      console.log('Fetched Feedback:', fetchedFeedback);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedFeedbackId(respondentID);
      setNewFeedback({
        ...fetchedFeedback,
     
      });
    } catch (error) {
      console.error(`Error fetching respondent with ID ${respondentID}:`, error.response.data);
    }
  };
  
  const updateExistingFeedback = async () => {
    try {
      const feedbackPayload = { ...newFeedback };
  
      console.log('Updated Feedback Payload:', feedbackPayload);
  
      await updateFeedback(selectedFeedbackId, feedbackPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating respondent with ID ${selectedFeedbackId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update respondent: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingFeedback = async (respondentID) => {
    try {
      await deleteFeedback(respondentID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting respondent with ID ${respondentID}:`, error.response.data);
      alert(`Failed to delete respondent: ${error.response.data.title}`);
    }
  };

  const closeAddFeedbackModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteFeedbackHandler = (respondentID) => {
    if (window.confirm(`Are you sure you want to delete respondent with ID ${respondentID}?`)) {
      deleteExistingFeedback(respondentID);
    }
  };

//Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterFeedbacks(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterFeedbacks(searchTerm, value);
  };

  const filterFeedbacks = (searchTerm, column) => {
    const filtered = feedback.filter((feedback) =>
      feedback[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredFeedbacks(filtered);
  };
  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Feedback</h1>
      <div className="p-4">
        
        <div className="flex justify-between">
        <button
          onClick={openAddFeedbackModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
          <FaPlus /> <span>Feedback</span>  
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {feedback.length > 0 ? (
                Object.keys(feedback[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <FeedbackTable
          feedback={filteredFeedbacks}
          openEditModal={openEditFeedbackModal}
          deleteFeedback={deleteFeedbackHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddFeedbackModal} contentLabel={editMode ? "Edit Feedback" : "Add Feedback"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit Feedback' : 'Add Feedback'}</h2>
        <FeedbackForm
          formValues={newFeedback} 
          handleInputChange={handleInputChange} 
          handleDateChange={handleDateChange} 
          errors={errors} 
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingFeedback : addNewFeedback} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddFeedbackModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddFeedback;
