import apiClient from './apiClient';

const addAjReporting = async (AjReportingData) => {
  try {
    const response = await apiClient.post('/api/ActivityReporting', AjReportingData);
    return response.data;
  } catch (error) {
    console.error('Error in addAjReporting:', error.response.data);
    throw error;
  }
};

const getAjReportingById = async (ID) => {
  try {
    const response = await apiClient.get(`/api/ActivityReporting/${ID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching AjReporting with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const updateAjReporting = async (ID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/ActivityReporting/${ID}`, updatedData);
    return response.data;
  } catch (error) {
    console.error(`Error updating AjReporting with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const deleteAjReporting = async (ID) => {
  try {
    await apiClient.delete(`/api/ActivityReporting/${ID}`);
  } catch (error) {
    console.error(`Error deleting AjReporting with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const getAjReporting = async () => {
  try {
    const response = await apiClient.get('/api/ActivityReporting');
    return response.data;
  } catch (error) {
    console.error('Error fetching AjReporting:', error.response?.data || error.message);
    throw error;
  }
};

export { 
  addAjReporting, 
  getAjReportingById, 
  updateAjReporting, 
  deleteAjReporting, 
  getAjReporting 
};
