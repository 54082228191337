import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import TestimonialsForm from "../../components/forms/testimonialsF";
import TestimonialsTable from "../../components/tables/testimonialsT";
import {
  addTestimonial as addTestimonialService,
  getTestimonialById,
  updateTestimonial,
  deleteTestimonial,
  getTestimonial,
} from "../../services/testimonialsS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddTestimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTestimonial, setNewTestimonial] = useState({
    userID: "",
    Username: "",
    Role: "",
    Description: "",
    Notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("Username");
  const [filteredTestimonials, setFilteredTestimonials] =
    useState(testimonials);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTestimonialId, setSelectedTestimonialId] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const fetchedTestimonials = await getTestimonial();
        console.log("Fetched Testimonials:", fetchedTestimonials); // Log the fetched data
        setTestimonials(fetchedTestimonials);
        setFilteredTestimonials(fetchedTestimonials);
      } catch (error) {
        console.error(
          "Error fetching Testimonials:",
          error.response?.data || error.message
        );
      }
    };

    fetchTestimonials();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTestimonial((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTestimonial = async () => {
    try {
      const TestimonialPayload = { ...newTestimonial };

      console.log("New Testimonial Payload:", TestimonialPayload);
      const addedTestimonial = await addTestimonialService(TestimonialPayload);
      setTestimonials((prev) => [...prev, addedTestimonial]);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        "Error adding Testimonial:",
        error.response?.data || error.message
      );
      setErrors(error.response?.data?.errors || {});
      alert(
        `Failed to add Testimonial: ${
          error.response?.data?.title
        }\nDetails: ${JSON.stringify(error.response?.data?.errors, null, 2)}`
      );
    }
  };

  const openAddTestimonialModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTestimonial({
      userID: "",
      Username: "",
      Role: "",
      Description: "",
      Notes: "",
    });
  };

  const openEditTestimonialModal = async (id) => {
    try {
      console.log("Fetching Testimonial with ID:", id);
      const fetchedTestimonial = await getTestimonialById(id);
      console.log("Fetched Testimonial:", fetchedTestimonial);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTestimonialId(id);
      setNewTestimonial({
        ...fetchedTestimonial,
      });
    } catch (error) {
      console.error(
        `Error fetching Testimonial with ID ${id}:`,
        error.response?.data || error.message
      );
    }
  };

  const updateExistingTestimonial = async () => {
    try {
      const TestimonialPayload = { ...newTestimonial };

      console.log("Updated Testimonial Payload:", TestimonialPayload);

      const updatedTestimonial = await updateTestimonial(
        selectedTestimonialId,
        TestimonialPayload
      );
    setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        `Error updating Testimonial with ID ${selectedTestimonialId}:`,
        error.response?.data || error.message
      );
      setErrors(error.response?.data?.errors || {});
      alert(
        `Failed to update Testimonial: ${
          error.response?.data?.title
        } ${JSON.stringify(error.response?.data?.errors, null, 2)}`
      );
    }
  };

  const deleteExistingTestimonial = async (userID) => {
    try {
      await deleteTestimonial(userID);
      setTestimonials((prev) => prev.filter((inst) => inst.userID !== userID));
    } catch (error) {
      console.error(
        `Error deleting Testimonial with ID ${userID}:`,
        error.response?.data || error.message
      );
      alert(`Failed to delete Testimonial: ${error.response?.data?.title}`);
    }
  };

  const closeAddTestimonialModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTestimonialHandler = (userID) => {
    if (
      window.confirm(
        `Are you sure you want to delete Testimonial with ID ${userID}?`
      )
    ) {
      deleteExistingTestimonial(userID);
    }
  };

   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterTestimonials(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterTestimonials(searchTerm, value);
  };

  const filterTestimonials = (searchTerm, column) => {
    const filtered = testimonials.filter((testimonial) =>
        testimonial[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredTestimonials(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Testimonials</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddTestimonialModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2">
            <FaPlus /> <span>Testimonial</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {testimonials.length > 0 ? (
                Object.keys(testimonials[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <TestimonialsTable
          testimonials={filteredTestimonials}
          openEditModal={openEditTestimonialModal}
          deleteTestimonial={deleteTestimonialHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddTestimonialModal}
        contentLabel={editMode ? "Edit Testimonial" : "Add Testimonial"}>
        <h2 className="text-xl mb-4">
          {editMode ? "Edit Testimonial" : "Add Testimonial"}
        </h2>
        <TestimonialsForm
          formValues={newTestimonial}
          handleInputChange={handleInputChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingTestimonial : addNewTestimonial}
            className="bg-primary text-white px-5 p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddTestimonialModal}
            className="outline outline-1 outline-primary text-primary p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddTestimonial;
