// src/utils/logger.js
import { logChange } from '../services/loggingService';

const createLogData = (actionType, entityName, description) => ({
  id: 0, // Adjust as needed, or handle ID generation
  username: 'user123', // Fetch current user data from context or state
  actionType,
  entityName,
  ipAddress: '127.0.0.1', // Ideally, get real IP address
  browser: navigator.userAgent,
  timeStamp: new Date().toISOString(),
  description,
});

export const logAction = async (actionType, entityName, description) => {
  const logData = createLogData(actionType, entityName, description);
  await logChange(logData);
};
