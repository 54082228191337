import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import {customStyles} from "../../styles/customStyles"
import ProjectStatusForm from '../../components/forms/projectStatusF';
import ProjectStatusTable from '../../components/tables/projectStatusT';
import { addProjectStatus, getProjectStatusById, updateProjectStatus, deleteProjectStatus, getProjectStatuses } from '../../services/projectStatusS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddProjectStatus = () => {
  const [projectstatuses, setProjectStatuses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProjectStatus, setNewProjectStatus] = useState({
    ProjectStatusID: '',
    StatusName: '',
    Notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredProjectStatus, setFilteredProjectStatus] = useState(projectstatuses);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedProjectStatusID, setSelectedProjectStatusID] = useState(null);

  useEffect(() => {
    const fetchProjectStatuses = async () => {
      try {
        const fetchedProjectStatuses = await getProjectStatuses();
        setProjectStatuses(fetchedProjectStatuses);
        setFilteredProjectStatus(fetchedProjectStatuses); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching project statuses:', error);
      }
    };

    fetchProjectStatuses();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProjectStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewProjectStatus = async () => {
    try {
      const statusPayload = { ...newProjectStatus };
      console.log('New project status payload:', statusPayload);
      const addedStatus = await addProjectStatus(statusPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      if (error.response) {
        console.error('Error adding project status:', error.response.data);
        setErrors(error.response.data.errors || {});
        alert(`Failed to add project status: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
      } else {
        console.error('Error adding project status:', error.message);
        alert('An unexpected error occurred.');
      }
    }
  };

  const openProjectStatusModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewProjectStatus({
      ProjectStatusID: '',
      StatusName: '',
      Notes: '',
    });
  };

  const openEditProjectStatusModal = async (ProjectStatusID) => {
    try {
      console.log(ProjectStatusID)
        const fetchedStatus = await getProjectStatusById(ProjectStatusID);
        setEditMode(true);
        setIsModalOpen(true);
        setSelectedProjectStatusID(ProjectStatusID);
        setNewProjectStatus({
            ...fetchedStatus,
        });
    } catch (error) {
        console.error(`Error fetching project status with ID ${ProjectStatusID}:`, error.response ? error.response.data : error.message);
    }
};

  const updateExistingProjectStatus = async () => {
    try {
      const statusPayload = { ...newProjectStatus };
      console.log('Updated project status payload:', statusPayload);
      const updatedStatus = await updateProjectStatus(selectedProjectStatusID, statusPayload);
      setProjectStatuses((prev) => prev.map(status => (status.ProjectStatusID === selectedProjectStatusID ? updatedStatus : status)));
      setIsModalOpen(false);
    } catch (error) {
      if (error.response) {
        console.error(`Error updating project status with ID ${selectedProjectStatusID}:`, error.response.data);
        setErrors(error.response.data.errors || {});
        alert(`Failed to update project status: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
      } else {
        console.error(`Error updating project status with ID ${selectedProjectStatusID}:`, error.message);
        alert('An unexpected error occurred.');
      }
    }
  };

  const deleteExistingProjectStatus = async (ProjectStatusID) => {
    try {
        await deleteProjectStatus(ProjectStatusID);
        setProjectStatuses((prev) => prev.filter(status => status.ProjectStatusID !== ProjectStatusID));
    } catch (error) {
        console.error(`Error deleting project status with ID ${ProjectStatusID}:`, error.response ? error.response.data : error.message);
        alert(`Failed to delete project status: ${error.response ? error.response.data.title : error.message}`);
    }
};

  const closeAddProjectStatusModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteProjectStatusHandler = (ProjectStatusID) => {
    if (window.confirm(`Are you sure you want to delete project status with ID ${ProjectStatusID}?`)) {
        deleteExistingProjectStatus(ProjectStatusID);
    }
};
//Search
const handleSearchChange = (event) => {
  const value = event.target.value;
  setSearchTerm(value);
  filterProjectStatuses(value, selectedColumn);
};
const handleFilterParameterChange = (event) => {
  const value = event.target.value;
  setSelectedColumn(value);
  filterProjectStatuses(searchTerm, value);
};

const filterProjectStatuses = (searchTerm, column) => {
  const filtered = projectstatuses.filter((projectStatus) =>
    projectStatus[column]
      .toString()
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  setFilteredProjectStatus(filtered);
};


  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Project Statuses</h1>
      <div className="p-4">
        
        <div className="flex justify-between">
        <button
          onClick={openProjectStatusModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
          <FaPlus />  <span>Project Status</span> 
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {projectstatuses.length > 0 ? (
                Object.keys(projectstatuses[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <ProjectStatusTable
          projectstatuses={filteredProjectStatus}
          openEditModal={openEditProjectStatusModal}
          deleteStatus={deleteProjectStatusHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddProjectStatusModal} contentLabel={editMode ? "Edit Project Status" : "Add Project Status"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit Project Status' : 'Add Project Status'}</h2>
        <ProjectStatusForm
          formValues={newProjectStatus}
          handleInputChange={handleInputChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingProjectStatus : addNewProjectStatus} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddProjectStatusModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddProjectStatus;
