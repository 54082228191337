import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { splitWordFunc } from '../../utils/splitWordFunc';
import { FiSearch } from "react-icons/fi";

import { FaPlus } from "react-icons/fa6";

import { customStyles } from "../../styles/customStyles";
import CTypeForm from "../../components/forms/awardCTypeF";
import CTypeTable from "../../components/tables/awardCTypeT";
import {
  addCType as addCTypeService,
  getCTypeById,
  updateCType,
  deleteCType,
  getCType,
} from "../../services/awardCTypeS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddCType = () => {
  const [CType, setCType] = useState([]);
  const [update,setUpdate] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCType, setNewCType] = useState({
    awardCTypeID: "",
    centerName: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("centerName");
  const [filteredCategories, setFilteredCategories] = useState(CType);
  const [searchTerm, setSearchTerm] = useState('');
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    const fetchedCType = async () => {
      try {
        const fetchedCType = await getCType();
        setCType(fetchedCType);
        setFilteredCategories(fetchedCType);
      } catch (error) {
        console.error("Error fetching CType:", error.response.data);
      }
    };

    fetchedCType();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewCType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewCType = async () => {
    try {
      const CTypePayload = {
      
        centerName: newCType.centerName,
        notes: newCType.notes,
      };

      
      const addedCType = await addCTypeService(CTypePayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding CType:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add CType: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };
  

  const openAddCTypeModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewCType({
      awardCTypeID: "",
      centerName: "",
      notes: "",
    });
  };

  const openEditCTypeModal = async (awardCTypeID) => {
    try {
      const fetchedCType = await getCTypeById(String(awardCTypeID)); // Changed to lowercase
      setEditMode(true);
      setSelectedID(awardCTypeID);
      setNewCType({
        ...fetchedCType,
      });
      setIsModalOpen(true); // Move after setting state
    } catch (error) {
      console.error(
        `Error fetching CType with ID ${awardCTypeID}:`,
        error.response.data
      );
    }
  };

  const updateExistingCType = async () => {
    try {
      const CTypePayload = { ...newCType };

      const updatedCType = await updateCType(selectedID, CTypePayload);
      const newCTypes =  await getCType()
      setCType(newCTypes);
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        `Error updating CType with ID ${selectedID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update CType: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingCType = async (awardCTypeID) => { // Changed to lowercase
    try {
      await deleteCType(awardCTypeID);
      setCType((prev) => prev.filter((inst) => inst.awardCTypeID !== awardCTypeID));
    } catch (error) {
      console.error(`Error deleting CType with ID ${awardCTypeID}:`, error);
      alert(`Failed to delete CType: ${error.response?.data?.title}`);
    }
  };

  const closeAddCTypeModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteCTypeHandler = (AwardCTypeID) => {
    if (
      window.confirm(`Are you sure you want to delete CType with ID ${AwardCTypeID}?`)
    ) {
      deleteExistingCType(AwardCTypeID);
    }
  };

   //Search 
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterCategories(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterCategories(searchTerm, value);
  };

  const filterCategories = (searchTerm, column) => {
    const filtered = CType.filter(category => 
      category[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCategories(filtered);
  };
  


  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Award Center Type</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddCTypeModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 mr-auto  flex justify-center items-center gap-2">
            <FaPlus />
            <span>Award Center Type</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {CType.length > 0 ? (
                Object.keys(CType[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <CTypeTable
          CType={filteredCategories}
          openEditModal={openEditCTypeModal}
          deleteCType={deleteCTypeHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddCTypeModal}
        contentLabel={editMode ? "Edit Award Center Type" : "Add Award Center Type"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit  Award Center Type" : "Add  Award Center Type"}
        </h2>
        <CTypeForm
          formValues={newCType}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingCType : addNewCType}
            className="bg-primary px-10 text-white p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddCTypeModal}
            className="outline outline-1 outline-primary px-10 text-primary p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddCType;