import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import FundingTypeForm from '../../components/forms/fundingTypeF';
import FundingTypeTable from '../../components/tables/fundingTypeT';
import { addFundingType as addFundingTypeService, getFundingTypeById, updateFundingType, deleteFundingType, getFundingType } from '../../services/fundingTypeS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddFundingType = () => {
  const [fundingtype, setFundingType] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFundingType, setNewFundingType] = useState({
    fundingID: '',
    fundingName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("fundingName");
  const [filteredFundingTypes, setFilteredFundingTypes] = useState(fundingtype);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedFundingTypeID, setSelectedFundingTypeID] = useState(null);

  useEffect(() => {
    const fetchedfundingtype = async () => {
      try {
        const fetchedFundingType = await getFundingType();
        setFundingType(fetchedFundingType);
        setFilteredFundingTypes(fetchedFundingType);
      } catch (error) {
        console.error('Error fetching fundingtype:', error.response.data);
      }
    };

    fetchedfundingtype();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFundingType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewFundingType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewFundingType = async () => {
    try {
      const fundingtypePayload = {
        fundingID: String(newFundingType.fundingID), // Ensure FundingTypeID is a string
        fundingName: newFundingType.fundingName,
        notes: newFundingType.notes,
      };
  
      await addFundingTypeService(fundingtypePayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding fundingtype:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add fundingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddFundingTypeModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewFundingType({
      fundingID: '',
      fundingName: '',
      notes: '',
    });
  };

  const openEditFundingTypeModal = async (fundingID) => {
    try {
      const fetchedFundingType = await getFundingTypeById(String(fundingID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedFundingTypeID(fundingID);
      setNewFundingType({
        ...fetchedFundingType,
      });
    } catch (error) {
      console.error(`Error fetching fundingtype with ID ${fundingID}:`, error.response.data);
    }
  };

  const updateExistingFundingType = async () => {
    try {
      const fundingtypePayload = { ...newFundingType };

      console.log('Updated fundingtype Payload:', fundingtypePayload);

      await updateFundingType(selectedFundingTypeID, fundingtypePayload);
     setUpdate(prev=>!prev);
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error updating fundingtype with ID ${selectedFundingTypeID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update fundingtype: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingFundingType = async (fundingID) => {
    try {
      await deleteFundingType(fundingID);
      setUpdate(prev=>!prev);
    } catch (error) {
      console.error(`Error deleting fundingtype with ID ${fundingID}:`, error.response.data);
      alert(`Failed to delete fundingtype: ${error.response.data.title}`);
    }
  };

  const closeAddFundingTypeModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteFundingTypeHandler = (fundingID) => {
    if (window.confirm(`Are you sure you want to delete fundingtype with ID ${fundingID}?`)) {
      deleteExistingFundingType(fundingID);
    }
  };


  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterFundingTypes(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterFundingTypes(searchTerm, value);
  };

  const filterFundingTypes = (searchTerm, column) => {
    const filtered = fundingtype.filter((fundingType) =>
      fundingType[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredFundingTypes(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">FundingType</h1>
      <div className="p-4">
        
        <div className="flex justify-between">
        <button
          onClick={openAddFundingTypeModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
           <FaPlus /> <span>Funding Type</span>
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {fundingtype.length > 0 ? (
                Object.keys(fundingtype[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <FundingTypeTable
          fundingtype={filteredFundingTypes}
          openEditModal={openEditFundingTypeModal}
          deleteFundingType={deleteFundingTypeHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddFundingTypeModal} contentLabel={editMode ? "Edit FundingType" : "Add fundingtype"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit FundingType' : 'Add FundingType'}</h2>
        <FundingTypeForm 
          formValues={newFundingType} 
          handleInputChange={handleInputChange} 
          handleDateChange={handleDateChange} 
          errors={errors} 
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingFundingType : addNewFundingType} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddFundingTypeModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddFundingType;
