import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { customStyles } from "../../styles/customStyles";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import TrainingForm from "../../components/forms/trainingF";
import TrainingTable from "../../components/tables/trainingT";
import {
  addTraining as addTrainingService,
  getTrainingById,
  updateTraining,
  deleteTraining,
  getTraining,
} from "../../services/trainingS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddTraining = () => {
  const [trainings, setTrainings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTraining, setNewTraining] = useState({
    trainingID: "",
    trainingName: "",
    institutionName: "",
    venue: "",
    date: "",
    categories: "",
    subCounty: "",
    county: "",
    coordinator: "",
    trainingLevel: "",
    trainingType: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredTrainings, setFilteredTrainings] = useState(trainings);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTrainingId, setSelectedTrainingId] = useState(null);

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const fetchedTrainings = await getTraining();
        setTrainings(fetchedTrainings);
        setFilteredTrainings(fetchedTrainings); // Initialize filtered participants
      } catch (error) {
        console.error("Error fetching trainings:", error.response.data);
      }
    };

    fetchTrainings();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTraining((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewTraining((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTraining = async () => {
    try {
      const trainingPayload = { ...newTraining };

      console.log("New Training Payload:", trainingPayload);
      const addedTraining = await addTrainingService(trainingPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error("Error adding training:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add training: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddTrainingModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTraining({
      trainingID: "",
      trainingName: "",
      institutionName: "",
      venue: "",
      date: "",
      categories: "",
      subCounty: "",
      county: "",
      coordinator: "",
      trainingLevel: "",
      trainingType: "",
      notes: "",
    });
  };

  const openEditTrainingModal = async (trainingID) => {
    try {
      console.log("Fetching training with ID:", trainingID);
      const fetchedTraining = await getTrainingById(trainingID);
      console.log("Fetched Training:", fetchedTraining);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTrainingId(trainingID);
      setNewTraining({
        ...fetchedTraining,
        date: fetchedTraining.date,
      });
    } catch (error) {
      console.error(
        `Error fetching training with ID ${trainingID}:`,
        error.response.data
      );
    }
  };

  const updateExistingTraining = async () => {
    try {
      const trainingPayload = { ...newTraining };

      console.log("Updated Training Payload:", trainingPayload);

      const updatedTraining = await updateTraining(
        selectedTrainingId,
        trainingPayload
      );
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        `Error updating training with ID ${selectedTrainingId}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update training: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingTraining = async (trainingID) => {
    try {
      await deleteTraining(trainingID);
      setUpdate((prev) => !prev);
    } catch (error) {
      console.error(
        `Error deleting training with ID ${trainingID}:`,
        error.response.data
      );
      alert(`Failed to delete training: ${error.response.data.title}`);
    }
  };

  const closeAddTrainingModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTrainingHandler = (trainingID) => {
    if (
      window.confirm(
        `Are you sure you want to delete training with ID ${trainingID}?`
      )
    ) {
      deleteExistingTraining(trainingID);
    }
  };

  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterTrainings(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterTrainings(searchTerm, value);
  };

  const filterTrainings = (searchTerm, column) => {
    const filtered = trainings.filter((training) =>
      training[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredTrainings(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Trainings</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddTrainingModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2">
            <FaPlus /> <span>Training</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {trainings.length > 0 ? (
                Object.keys(trainings[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <TrainingTable
          trainings={filteredTrainings}
          openEditModal={openEditTrainingModal}
          deleteTraining={deleteTrainingHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddTrainingModal}
        contentLabel={editMode ? "Edit Training" : "Add Training"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit Training" : "Add Training"}
        </h2>
        <TrainingForm
          formValues={newTraining}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingTraining : addNewTraining}
            className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddTrainingModal}
            className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddTraining;
