import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStylesModal } from '../../styles/customStylesModal';

const AjApprovalModal = ({ isOpen, onClose, application, onApprove, onReject }) => {
  const [assessors, setAssessors] = useState(''); 
  const [isReviewed, setIsReviewed] = useState(application?.approval !== null); // Determine if a decision has been made

  if (!application) {
    return null;
  }

    // Function to determine approval status
    const approvalStatus = application.approval === true 
    ? 'Approved' 
    : application.approval === false && !application.isReviewed 
    ? 'Pending' 
    : 'Rejected';

  // Function to get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'text-green-600';
      case 'Rejected':
        return 'text-red-600';
      default:
        return 'text-yellow-600';
    }
  };

  const statusColor = getStatusColor(approvalStatus);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStylesModal} 
      contentLabel="Review Adventurous Journey Application"
    >
      <div className="bg-white rounded-xl p-8 space-y-6">
        <h2 className="text-3xl font-bold text-indigo-700 border-b-2 border-indigo-200 pb-2">
          Review Adventurous Journey Application
        </h2>
        <div className="space-y-4">
          <p><strong>Award Center:</strong> {application.awardCentre}</p>
          <p><strong>Award Leader:</strong> {application.awardLeader}</p>
          <p><strong>Region:</strong> {application.region}</p>
          <p><strong>Activity Name:</strong> {application.activityName}</p>
          <p><strong>Activity Date:</strong> {application.activityDate}</p>
          <p><strong>Apply Date:</strong> {application.applyDate}</p>
          <p><strong>Assessors:</strong> {application.assessors}</p>
          <p><strong>No. of Participants:</strong> {application.participantsNo}</p>
          <p><strong>Upload Form:</strong> {application.uploadForm ? 'Uploaded' : 'Not Uploaded'}</p>
          <p><strong>Approval Status:</strong> {application.approval ? 'Approved' : 'Rejected' }</p>
          <p>
            <strong>Approval Status:</strong>{' '}
            <span className={`font-semibold ${statusColor}`}>{approvalStatus}</span>
          </p>
          <p><strong>Consent:</strong> {application.consent ? 'Yes' : 'No'}</p>
          <p><strong>Notes:</strong> {application.notes}</p>
           {/* New input field for assessors */}
           <div className="mt-4">
            <label htmlFor="assessors" className="block text-gray-700 font-bold mb-2">Assign Assessors:</label>
            <input
              type="text"
              id="assessors"
              value={assessors}
              onChange={(e) => setAssessors(e.target.value)}
              placeholder="Enter assessors"
              className="border rounded-lg py-2 px-4 w-full"
            />
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={() => onApprove(application.id, assessors)}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg"
          >
            Approve
          </button>
          <button
            onClick={() => {
              onReject(application.id);
              setIsReviewed(true); // Mark as reviewed after rejection
            }}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg"
          >
            Reject
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AjApprovalModal;
