import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import AjReportingForm from '../../components/forms/ajReportingF';
import AjReportingTable from '../../components/tables/ajReportingT';
import { addAjReporting, getAjReportingById, updateAjReporting, deleteAjReporting, getAjReporting  } from '../../services/ajsReportingS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddAjReporting = () => {
  const [AjReporting, setAjReportings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAjReporting, setNewAjReporting] = useState({
    
    awardCentre: '',
    awardLeader: '',
    activityName: '',
    uploadReport: null, 
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredAjReportings, setFilteredAjReportings] = useState(AjReporting);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedAjReportingId, setSelectedAjReportingId] = useState(null);

  useEffect(() => {
    const fetchAjReportings = async () => {
      try {
        const fetchedAjReportings = await getAjReporting();
        setAjReportings(fetchedAjReportings);
        setFilteredAjReportings(fetchedAjReportings);
      } catch (error) {
        console.error('Error fetching AjReporting:', error.response.data);
      }
    };

    fetchAjReportings();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAjReporting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewAjReporting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewAjReporting = async () => {
    try {
      const AjReportingPayload = { ...newAjReporting };
      await addAjReporting(AjReportingPayload);
      setIsModalOpen(false);
      setUpdate(prev=>!prev)
      setErrors({});
    } catch (error) {
      console.error('Error adding AjReporting:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add AjReporting: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddAjReportingModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewAjReporting({
        awardCentre: '',
        awardLeader: '',
        activityName: '',
        uploadReport: null, 
        notes: '',
    });
  };

  const openEditAjReportingModal = async (ID) => {
    try {
      console.log('Fetching AjReporting with ID:', ID);
      const fetchedAjReporting = await getAjReportingById(ID);
      console.log('Fetched AjReporting:', fetchedAjReporting);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedAjReportingId(ID);
      setNewAjReporting({
        ...fetchedAjReporting,
        startDate: fetchedAjReporting.startDate,
        endDate: fetchedAjReporting.endDate,
      });
    } catch (error) {
      console.error(`Error fetching AjReporting with ID ${ID}:`, error.response.data);
    }
  };  
  
  const updateExistingAjReporting = async () => {
    try {
      const AjReportingPayload = { ...newAjReporting };
      await updateAjReporting(selectedAjReportingId, AjReportingPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating AjReporting with ID ${selectedAjReportingId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update AjReporting: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingAjReporting = async (ID) => {
    try {
      await deleteAjReporting(ID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting AjReporting with ID ${ID}:`, error.response.data);
      alert(`Failed to delete AjReporting: ${error.response.data.title}`);
    }
  };

  const closeAddAjReportingModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteAjReportingHandler = (ID) => {
    if (window.confirm(`Are you sure you want to delete AjReporting with ID ${ID}?`)) {
      deleteExistingAjReporting(ID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterAjReportings(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterAjReportings(searchTerm, value);
    };
  
    const filterAjReportings = (searchTerm, column) => {
      const filtered = AjReporting.filter((AjReporting) =>
        AjReporting[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredAjReportings(filtered);
    };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">AjReportings</h1>
      <div className="p-4">
        <div className="flex justify-between">
        <button
          onClick={openAddAjReportingModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
        <FaPlus />  <span>AjReporting</span>   
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {AjReporting.length > 0 ? (
                Object.keys(AjReporting[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <AjReportingTable
          AjReporting={filteredAjReportings}
          openEditModal={openEditAjReportingModal}
          deleteAjReporting={deleteAjReportingHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddAjReportingModal} contentLabel={editMode ? "Edit AjReporting" : "Add AjReporting"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit AjReporting' : 'Add AjReporting'}</h2>
        <AjReportingForm 
          formValues={newAjReporting} 
          handleInputChange={handleInputChange} 
          handleDateChange={handleDateChange} 
          errors={errors} 
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingAjReporting : addNewAjReporting} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddAjReportingModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddAjReporting;
