import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRoleById, addPermissionToRole, removePermissionFromRole } from "../../../../../services/rolesS";
import { getPermissions } from "../../../../../services/permissionsS";

const PermissionRoles = () => {
  const [role, setRole] = useState({ permissions: [] });
  const [permissions, setPermissions] = useState([]);
  const { id } = useParams(); // `id` is the role ID

  useEffect(() => {
    fetchRole();
    fetchPermissions();
  }, []);

  const fetchRole = async () => {
    try {
      const fetchedRole = await getRoleById(id);
      setRole(fetchedRole);
    } catch (error) {
      console.error("Error fetching Role:", error.response.data);
    }
  };

  const fetchPermissions = async () => {
    try {
      const fetchedPermissions = await getPermissions();
      setPermissions(fetchedPermissions);
    } catch (error) {
      console.error("Error fetching Permissions:", error.response.data);
    }
  };

  const checkIfIncludes = (name, arrayOfObjects) => {
    return arrayOfObjects.some(obj => obj.name === name);
  };

  const handleCheckboxChange = async (permission) => {
    try {
      if (checkIfIncludes(permission.name, role.permissions)) {
        // Remove permission from role
        await removePermissionFromRole(id, permission.id); // Send a request to remove the permission
        setRole({
          ...role,
          permissions: role.permissions.filter(p => p.name !== permission.name),
        });
      } else {
        // Add permission to role
        await addPermissionToRole({roleId: id, permissionId: permission.id}); // Send a request to add the permission
        setRole({
          ...role,
          permissions: [...role.permissions, permission],
        });
      }
    } catch (error) {
      console.error("Error updating permissions:", error.response.data);
    }
  };

  return (
    <div>
      <h2 className="subtitle2 mb-4 capitalize">
        {role.name} Permissions
      </h2>
      <div className="d-flex flex-wrap">
        {permissions &&
          permissions.map((p) => {
            return (
              <div className="inline-flex items-center" key={p.id}>
                <label
                  className="relative flex cursor-pointer items-center rounded-full p-3"
                  data-ripple-dark="true">
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(p)}
                    checked={checkIfIncludes(p.name, role.permissions)}
                    className="peer relative h-5 w-5 cursor-pointer appearance-none rounded border border-slate-300 shadow hover:shadow-md transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-slate-400 before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-white hover:before:opacity-10"
                  />
                  <span className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1">
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="cursor-pointer text-slate-600 text-sm">
                  {p.name}
                </label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PermissionRoles;
