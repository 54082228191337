import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import ParticipantForm from "../../components/forms/participantF";
import ParticipantTable from "../../components/tables/participantT";
import ParticipantDetailsModal from "../participants/participantDetailsModal"
import ParticipantDetailsAndAwardsModal from "../participants/participantDetailsModal";
import { getAwards } from "../../services/participantAwardS"
import {
  addParticipant as addParticipantService,
  getParticipantById,
  updateParticipant,
  deleteParticipant,
  getParticipant,
} from "../../services/participantS";
import Layout from "../../components/Layout/layout";
import * as Yup from 'yup';
// import { participantSchema } from '../../components/forms/participantF';


Modal.setAppElement("#root");

const generateAdminNumber = (index) => {
  const currentYear = new Date().getFullYear();
  const year = currentYear % 100; // Get the last two digits of the year
  const formattedIndex = String(index).padStart(3, '0'); // Example: 001, 002, etc.
  return `PAK-P-${formattedIndex}-${year}`;
};

const AddParticipant = () => {
  const [participants, setParticipants] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newParticipant, setNewParticipant] = useState({
    adminNumber: "",
    name: "",
    dob: "",
    gender: "",
    age: "",
    religion: "",
    ethnicity: "",
    nationality: "",
    phoneNumber: "",
    email: "",
    institutionName: "",
    region: "",
    subCounty: "",
    county: "",
    guardianName: "",
    guardianContact: "",
    emergencyCName: "",
    emergencyCNumber: "",
    emergencyCRelation: "",
    paymentStatus: "",
    marginalised: "",
    atRisk: "",
    notes: "",
  });
  const [passportPhoto, setPassportPhoto] = useState(null); // New state to track the file
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredParticipants, setFilteredParticipants] = useState(participants);
  const [searchTerm, setSearchTerm] = useState("");
  const [update, setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [nextIndex, setNextIndex] = useState(0); // Track next index for Admin Number
  const [isParticipantDetailsModalOpen, setIsParticipantDetailsModalOpen] = useState(false);
  const [participantDetails, setParticipantDetails] = useState(null);
  const [isDetailsAndAwardsModalOpen, setIsDetailsAndAwardsModalOpen] = useState(false);
  const [newlyAddedParticipant, setNewlyAddedParticipant] = useState(null);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const fetchedParticipants = await getParticipant();
        setParticipants(fetchedParticipants);
        setFilteredParticipants(fetchedParticipants);

        // Calculate the next index based on the current year
        const currentYear = new Date().getFullYear();
        const year = currentYear % 100; // Get the last two digits of the year

        // Find the highest existing index for the current year
        const latestIndex = Math.max(
          ...fetchedParticipants
            .filter(p => p.adminNumber.includes(`-${year}`))
            .map(p => parseInt(p.adminNumber.split('-')[2], 10)),
          0
        );

        setNextIndex(latestIndex + 1); // Increment for the next participant
      } catch (error) {
        console.error("Error fetching participants:", error.response.data);
      }
    };

    fetchParticipants();
  }, [update]);

  const openParticipantDetails = async (adminNumber) => {
    try {
      const participant = await getParticipantById(adminNumber);


      if (participant) {
        const awards = await getAwards();
        const participantAwards = awards.filter(award => award.adminNumber === adminNumber)
        const awardDetails = participantAwards.map(award => ({
          levelName: award.levelName,
          status: award.status,
        }));
        const awardDisplay = awardDetails
          .map(detail => `${detail.levelName} - ${detail.status} `)
          .join(', ');
        const details = {
          adminNumber: participant.adminNumber,
          institutionName: participant.institutionName,
          name: participant.name,
          dob: participant.dob,
          gender: participant.gender,
          age: participant.age,
          phoneNumber: participant.phoneNumber,
          email: participant.email,
          atRisk: participant.atRisk,
          passportPhoto: participant.passportPhoto,
          awards: awardDisplay
        };

        setNewlyAddedParticipant(participant);
        setIsParticipantDetailsModalOpen(true);
        setParticipantDetails(details);

      }
    } catch (error) {
      console.error('Error fetching participant details:', error);
    }
  };

  const closeParticipantDetailsModal = () => {
    setIsParticipantDetailsModalOpen(false);
    setParticipantDetails(null);
  };


  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    try {
      setNewParticipant((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors({ ...errors, [name]: undefined });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message }); // Update errors state with error message
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewParticipant((prevData) => ({
      ...prevData,
      [name]: files[0], // assuming you want to handle single file uploads
    }));

    // You can add validation logic here for file type and size if needed
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewParticipant((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const openAddParticipantModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewParticipant({
      adminNumber: generateAdminNumber(nextIndex),
      name: "",
      dob: "",
      gender: "",
      age: "",
      religion: "",
      ethnicity: "",
      nationality: "",
      phoneNumber: "",
      email: "",
      institutionName: "",
      subCounty: "",
      county: "",
      awardLevel: "",
      guardianName: "",
      guardianContact: "",
      emergencyCName: "",
      emergencyCNumber: "",
      emergencyCRelation: "",
      paymentStatus: "",
      marginalised: "",
      atRisk: "",
      notes: "",
    });
  };

  const openEditParticipantModal = async (adminNumber) => {
    try {
      const fetchedParticipant = await getParticipantById(
        adminNumber
      );
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedParticipantId(adminNumber);
      setNewParticipant({
        ...fetchedParticipant,
        dateOfBirth: fetchedParticipant.dateOfBirth,
      });
    } catch (error) {
      console.error(
        `Error fetching participant with ID ${adminNumber}:`,
        error.response.data
      );
    }
  };
  const updateExistingParticipant = async () => {
    try {
      // Create FormData to handle file upload
      const formData = new FormData();
      Object.keys(newParticipant).forEach(key => {
        formData.append(key, newParticipant[key]);
      });
      if (passportPhoto) {
        formData.append('passportPhoto', passportPhoto); // Append file to FormData
      }

      const updatedParticipant = await updateParticipant(
        selectedParticipantId,
        formData // Send FormData to the service
      );

      setUpdate(prev => prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update participant: ${error.response.data.title
        }\nDetails: ${JSON.stringify(error, null, 2)}`
      );
    }
  };

  const deleteExistingParticipant = async (adminNumber) => {
    try {
      await deleteParticipant(adminNumber);
      setUpdate(prev => !prev)
    } catch (error) {
      console.error(
        `Error deleting participant with ID ${adminNumber}:`,
        error.response.data
      );
      alert(`Failed to delete participant: ${error.response.data.title}`);
    }
  };

  const closeAddParticipantModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteParticipantHandler = (adminNumber) => {
    if (
      window.confirm(
        `Are you sure you want to delete participant with ID ${adminNumber}?`
      )
    ) {
      deleteExistingParticipant(adminNumber);
    }
  };
  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterParticipants(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterParticipants(searchTerm, value);
  };

  const filterParticipants = (searchTerm, column) => {
    const filtered = participants.filter((participant) =>
      participant[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredParticipants(filtered);
  };

  const closeDetailsAndAwardsModal = () => {
    setIsDetailsAndAwardsModalOpen(false);
    setNewlyAddedParticipant(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create FormData to hold the participant object and files
    const formData = new FormData();

    // Append each key-value pair to the FormData object

    formData.append("Name", newParticipant.name)
    formData.append("AdminNumber", newParticipant.adminNumber)
    formData.append("County", newParticipant.county)
    formData.append("Email", newParticipant.email)
    formData.append("EmergencyCName", newParticipant.emergencyCName)
    formData.append("Age", newParticipant.age)
    formData.append("EmergencyCNumber", newParticipant.emergencyCNumber)
    formData.append("EmergencyCRelation", newParticipant.emergencyCRelation)
    formData.append("Ethnicity", newParticipant.ethnicity)
    formData.append("Gender", newParticipant.gender)
    formData.append("GuardianContact", newParticipant.guardianContact)
    formData.append("InstitutionName", newParticipant.institutionName)
    formData.append("Marginalised", newParticipant.marginalised)
    formData.append("Nationality", newParticipant.nationality)
    formData.append("Notes", newParticipant.notes)
    formData.append("PaymentStatus", newParticipant.paymentStatus)
    formData.append("PhoneNumber", newParticipant.phoneNumber)
    formData.append("Region", newParticipant.region)
    formData.append("Religion", newParticipant.religion)
    formData.append("SubCounty", newParticipant.subCounty)
    formData.append("GuardianName", newParticipant.guardianName)
    formData.append("AtRisk", newParticipant.atRisk)
    // Append files

    if (newParticipant.doc) formData.append('Doc', newParticipant.doc);
    if (newParticipant.passportPhoto) formData.append('PassportPhoto', newParticipant.passportPhoto);

    try {
      // Create the participant

      const response = await fetch(process.env.REACT_APP_API_BASE_URL + "/api/Participants", {
        method: "POST",
        body: formData // Sending the formData with both participant object and files
      });
      const responseData = await response.json();


      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      // Set newly added participant data
      setNewlyAddedParticipant(responseData);
      setIsModalOpen(false);
      setErrors({});
      setUpdate((prev) => !prev);
      setIsDetailsAndAwardsModalOpen(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Handle Yup validation errors
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        console.error('Validation errors:', validationErrors);
        setErrors(validationErrors);
      } else {
        console.error('Error submitting the form:', error);
        alert(`Failed to add participant: ${error.response?.data?.title || 'Unknown error'}`);
      }
    }
  };


  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Participants</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddParticipantModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2">
            <FaPlus /> <span>Participant</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {participants.length > 0 ? (
                Object.keys(participants[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <ParticipantTable
          participants={filteredParticipants}
          openEditModal={openEditParticipantModal}
          deleteParticipant={deleteParticipantHandler}
          openParticipantDetail={openParticipantDetails}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddParticipantModal}
        contentLabel={editMode ? "Edit Participant" : "Add Participant"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit Participant" : "Add Participant"}
        </h2>
        <form >
          <ParticipantForm
            formValues={newParticipant}
            handleInputChange={handleInputChange}
            handleDateChange={handleDateChange}
            handleFileChange={handleFileChange}
            errors={errors}
          />
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              onSubmit={editMode ? updateExistingParticipant : handleSubmit }
              className="bg-primary px-10 text-white p-2 rounded mr-2">
              {editMode ? "Update" : "Save and Add Awards"}
            </button>
            <button
              type="button"
              onClick={closeAddParticipantModal}
              className="outline outline-1 outline-primary text-primary px-10 p-2 rounded">
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      <ParticipantDetailsModal
        isOpen={isParticipantDetailsModalOpen}
        onRequestClose={closeParticipantDetailsModal}
        participantDetails={participantDetails}
      />
      <ParticipantDetailsAndAwardsModal
        isOpen={isDetailsAndAwardsModalOpen}
        onRequestClose={closeDetailsAndAwardsModal}
        participantDetails={newlyAddedParticipant}
      />
    </Layout>
  );
};

export default AddParticipant;
