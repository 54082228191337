import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import  {customStyles} from "../../styles/customStyles"
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import TrainingLevelForm from '../../components/forms/trainingLevelF';
import TrainingLevelTable from '../../components/tables/trainingLevelT';
import { addTrainingLevel as addTrainingLevelService, getTrainingLevelById, updateTrainingLevel, deleteTrainingLevel, getTrainingLevel } from '../../services/trainingLevelS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddTrainingLevel = () => {
  const [traininglevels, setTrainingLevel] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTrainingLevel, setNewTrainingLevel] = useState({
    trainingLevelID: '',
    levelName: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("levelName");
  const [filteredTrainingLevels, setFilteredTrainingLevels] = useState(traininglevels);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedTrainingLevelID, setSelectedTrainingLevelID] = useState(null);

  useEffect(() => {
    const fetchedtrainingLevel = async () => {
      try {
        const fetchedTrainingLevel = await getTrainingLevel();
        setTrainingLevel(fetchedTrainingLevel);
        setFilteredTrainingLevels(fetchedTrainingLevel);
      } catch (error) {
        console.error('Error fetching trainingLevel:', error.response.data);
      }
    };

    fetchedtrainingLevel();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingLevel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewTrainingLevel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewTrainingLevel = async () => {
    try {
      const trainingLevelPayload = {
        trainingLevelID: String(newTrainingLevel.trainingLevelID), // Ensure TrainingLevelID is a string
        levelName: newTrainingLevel.levelName,
        notes: newTrainingLevel.notes,
      };
       await addTrainingLevelService(trainingLevelPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      setErrors(error.response.data.errors || {});
      alert(`Failed to add trainingLevel: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };
  

  const openAddTrainingLevelModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewTrainingLevel({
      trainingLevelID: '',
      levelName: '',
      notes: '',
    });
  };

  const openEditTrainingLevelModal = async (trainingLevelID) => {
    try {
      const fetchedTrainingLevel = await getTrainingLevelById(String(trainingLevelID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedTrainingLevelID(trainingLevelID);
      setNewTrainingLevel({
        ...fetchedTrainingLevel,
      });
    } catch (error) {
      console.error(`Error fetching trainingLevel with ID ${trainingLevelID}:`, error.response.data);
    }
  };

  const updateExistingTrainingLevel = async () => {
    try {
      const trainingLevelPayload = { ...newTrainingLevel };

      await updateTrainingLevel(selectedTrainingLevelID, trainingLevelPayload);
      setUpdate((prev)=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error(`Error updating trainingLevel with ID ${selectedTrainingLevelID}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update trainingLevel: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingTrainingLevel = async (trainingLevelID) => {
    try {
      await deleteTrainingLevel(trainingLevelID);
      setUpdate((prev)=>!prev)
    } catch (error) {
      console.error(`Error deleting trainingLevel with ID ${trainingLevelID}:`, error.response.data);
      alert(`Failed to delete trainingLevel: ${error.response.data.title}`);
    }
  };

  const closeAddTrainingLevelModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteTrainingLevelHandler = (trainingLevelID) => {
    if (window.confirm(`Are you sure you want to delete trainingLevel with ID ${trainingLevelID}?`)) {
      deleteExistingTrainingLevel(trainingLevelID);
    }
  };

   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterTrainingLevels(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterTrainingLevels(searchTerm, value);
  };

  const filterTrainingLevels = (searchTerm, column) => {
    const filtered = traininglevels.filter((participant) =>
      participant[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredTrainingLevels(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Training Level</h1>
      <div className="p-4">
       
        <div className="flex justify-between">
        <button
          onClick={openAddTrainingLevelModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
            <FaPlus /><span>Training Level</span>    
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {traininglevels.length > 0 ? (
                Object.keys(traininglevels[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <TrainingLevelTable
          traininglevels={filteredTrainingLevels}
          openEditModal={openEditTrainingLevelModal}
          deleteTrainingLevel={deleteTrainingLevelHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddTrainingLevelModal} contentLabel={editMode ? "Edit TrainingLevel" : "Add trainingLevel"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit TrainingLevel' : 'Add TrainingLevel'}</h2>
        <TrainingLevelForm 
          formValues={newTrainingLevel} 
          handleInputChange={handleInputChange} 
          handleDateChange={handleDateChange} 
          errors={errors} 
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingTrainingLevel : addNewTrainingLevel} className="bg-primary -px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddTrainingLevelModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddTrainingLevel;
