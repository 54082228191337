import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import Layout from "../../components/Layout/layout";
import ProjectForm from "../../components/forms/projectForm";
import ProjectTable from "../../components/tables/projectTable";
import {
  addProject as addProjectService, 
  getProjectById,
  updateProject,
  deleteProject,
  getProjects,
} from "../../services/projectService";

Modal.setAppElement("#root");

// Function to generate Project ID
const generateProjectID = (index) => {
  const currentYear = new Date().getFullYear();
  const formattedIndex = String(index).padStart(3, '0'); // Example: 001, 002, etc.
  return `PRJ-${formattedIndex}-${currentYear}`;
};

const AddProject = () => {
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProject, setNewProject] = useState({
    projectId: "",
    projectName: "",
    institutionName: "",
    institutionName2: "",
    institutionName3: "",
    region: "",
    startDate: "",
    endDate: "",
    cost: "",
    subCounty: "",
    county: "",
    description: "",
    coordinator: "",
    notes: "",
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [update, setUpdate] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [nextProjectIndex, setNextProjectIndex] = useState(0);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const fetchedProjects = await getProjects();
        setProjects(fetchedProjects);
        setFilteredProjects(fetchedProjects); // Initialize filtered participants
       
        // Get the current full year (e.g., 2024)
    const currentYear = new Date().getFullYear();

    // Find the highest existing index for the current year
    const latestIndex = Math.max(
      ...fetchedProjects
        .filter((p) => p.projectID.includes(`-${currentYear}`))
        .map((p) => parseInt(p.projectID.split('-')[1], 10)), // [1] for the index part
      0 // Default to 0 if no projects are found
    );
        setNextProjectIndex(latestIndex + 1); // Increment for the next project
      } catch (error) {
        console.error("Error fetching projects:", error.response.data);
      }
    };

    fetchProjects();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  
  const addNewProject = async () => {
    try {
      const projectPayload = { ...newProject };
      const addedProject = await addProjectService(projectPayload);
      setUpdate((prev) => !prev);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error("Error adding project:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add project: ${error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddProjectModal = () => {
    setEditMode(false);
    setIsModalOpen(true);

    setNewProject({
      projectID: generateProjectID(nextProjectIndex),
      projectName: "",
      institutionName: "",
      institutionName2: "",
      institutionName3: "",
      region: "",
      startDate: "",
      endDate: "",
      cost: "",
      subCounty: "",
      county: "",
      description: "",
      coordinator: "",
      notes: "",
    });
  };

  const openEditProjectModal = async (project) => {
    try {
      const fetchedProject = await getProjectById(project.projectID);
      setEditMode(true); // Sets the form to edit mode
      setIsModalOpen(true); // Opens the modal
      setSelectedProjectId(project.projectID); // Sets the ID of the selected project

      setNewProject({
        ...fetchedProject,
        startDate: fetchedProject.startDate,
        endDate: fetchedProject.endDate,
      }); // Initializes form fields with fetched project data
    } catch (error) {
      console.error(
        `Error fetching project with ID ${project.projectID}:`,
        error.message
      );
      if (error.response) {
        console.error(error.response.data);
      } else {
        alert(
          "Failed to fetch project. Please check your network connection or CORS configuration."
        );
      }
    }
  };

  const updateExistingProject = async () => {
    try {
      const projectPayload = { ...newProject };
      const updatedProject = await updateProject(
        selectedProjectId,
        projectPayload
      );

      const updatedProjecta = await getProjects();
      setNewProject(updatedProjecta);

      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(
        `Error updating project with ID ${selectedProjectId}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update project: ${error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingProject = async (projectID) => {
    try {
      await deleteProject(projectID);
      setUpdate((prev) => !prev);
    } catch (error) {
      console.error(
        `Error deleting project with ID ${projectID}:`,
        error.response.data
      );
      alert(`Failed to delete project: ${error.response.data.title}`);
    }
  };

  const closeAddProjectModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteProjectHandler = (projectID) => {
    if (
      window.confirm(
        `Are you sure you want to delete project with ID ${projectID}?`
      )
    ) {
      deleteExistingProject(projectID);
    }
  };
  //Search
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterProjects(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterProjects(searchTerm, value);
  };

  const filterProjects = (searchTerm, column) => {
    const filtered = projects.filter((project) =>
      project[column]
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Projects</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddProjectModal}
            className="bg-blue-500 text-white p-2 rounded mb-4  flex justify-center items-center mr-auto gap-2">
            <FaPlus /> <span>Project</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {projects.length > 0 ? (
                Object.keys(projects[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>

        <ProjectTable
          projects={filteredProjects}
          openEditModal={openEditProjectModal}
          deleteProject={deleteProjectHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddProjectModal}
        contentLabel={editMode ? "Edit Project" : "Add Project"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit Project" : "Add Project"}
        </h2>
        <ProjectForm
          formValues={newProject}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingProject : addNewProject}
            className="bg-primary text-white px-5 p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddProjectModal}
            className="outline outline-1 outline-primary px-5 text-primary p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddProject;
