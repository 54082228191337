import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/layout";
import Chart from "./components/Chart/chart";
import { getInstitutions } from "../../services/awardCenterS";
import { getParticipant } from "../../services/participantS";
import { getAwards } from "../../services/participantAwardS";
import { getPartnership } from "../../services/partnershipS";
import { getProjects } from "../../services/projectService";
import { GoOrganization } from "react-icons/go";
import { FaHandshake } from "react-icons/fa";
import UserMap from "./Map";
import { IoManOutline } from "react-icons/io5";
import { IoWomanOutline } from "react-icons/io5";

import { FcBusinesswoman } from "react-icons/fc";
import { MdGroups2 } from "react-icons/md";
import { GoProjectSymlink } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import BoxWrapper from "./components/BoxWrapper/boxWrapper"
import AwardGainedParticipantCard from "./components/AwardGainedParticipantsCard";
import ProjectsSummaryCard from "./components/ProjectsSummaryCard";
export default function DashboardStatsGrid() {
  const [awardCenters, setAwardCenters] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [participantsAwards, setParticipantsAwards] = useState([]);
  const [projects, setProjects] = useState([]);
  const [partners, setPartners] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getI();
  }, []);

  const getI = async () => {
    const data = await getInstitutions();
    const participants = await getParticipant();
    const participantsAwards = await getAwards();
    const projects = await getProjects();
    const partners = await getPartnership();

    setAwardCenters(data);
    setParticipants(participants);
    setProjects(projects);
    setPartners(partners);
    setParticipantsAwards(participantsAwards)
  };

  const barData = {
    labels: ["Bronze", "Silver", "Gold","Bronze & Gold", "Silver & Gold","Silver & Bronze"],
    datasets: [
      {
        label: "Highest Award gained per Award Level",
        data: [10, 15, 20,10],
        backgroundColor: [
          "rgba(205, 127, 50, 0.2)", // Bronze color
          "rgba(192, 192, 192, 0.2)", // Silver color
          "rgba(255, 215, 0, 1)", // Gold color
          "rgba(255, 215, 0, 0.2)", // Gold color
        ],
        borderColor: [
          "rgba(205, 127, 50, 1)", // Bronze color
          "rgba(192, 192, 192, 1)", // Silver color
          "rgba(255, 215, 0, 1)", // Gold color
          "rgba(255, 215, 0, 1)", // Gold color
        ],
        borderWidth: 1,
      },
    ],
  };

const dashboardCardsConfig = [
  {
    path: "/institutions/addInstitutions",
    bgColor: "bg-white",
    bgImage: "bg-card-bg-3",
    Icon: <GoOrganization className="text-4xl text-primary" />,
    textColor: "text-main",
    primaryText: "Total Centers",
    secondaryText: awardCenters.length,
    children: false
  },
  {
    path: "/participants/participant",
    bgColor: "bg-white",
    bgImage: "bg-card-bg-1",
    Icon:  <MdGroups2 className="text-4xl text-primary" />,
    textColor: "text-main",
    primaryText: "Active Participants",
    secondaryText: awardCenters.length,
    children: true
  },
  
  {
    path: "/project/project",
    bgColor: "bg-white",
    bgImage: "bg-card-bg-2",
    Icon: <GoProjectSymlink className="text-4xl text-primary" />,
    textColor: "text-main",
    primaryText: "Projects",
    secondaryText: projects.length,
    children: false
  },
  {
    path: "/partnership/partnership",
    bgColor: "bg-white",
    bgImage: "bg-card-bg-2",
    Icon: <FaHandshake className="text-4xl text-primary" />,
    textColor: "text-main",
    primaryText: "Partners",
    secondaryText: partners.length,
    children: false
  },
];


  return (
    <Layout>
      <div className="px-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  md:gap-3  ">
        {dashboardCardsConfig.map((config) => (
          <BoxWrapper
            className={` ${config.bgColor} ${config.bgImage} flex justify-center gap-4`}
            onclick={() => navigate(config.path)}>
            <div className={!config.children ?"flex flex-col justify-center  items-center" :"flex flex-col justify-center items-center"}>
            {!config.children &&
              <span className={`subtitle1 font-[600] font-Satoshi  mr-4 text-[20px]  ${config.textColor}`}>
                {config.secondaryText}
              </span>
            }
              {config.children && (
              <div className="flex  items-center gap-2 mt-3 mb-3">
                <strong className="text-xl text-gray-700 flex justify-center items-center gap-1 ">
                  <span>
                    <IoManOutline className="text-primary  text-bold text-[30px]" />
                  </span>
                  <span>
                    {participants.filter((p) => p.gender === "Male").length}
                  </span>
                </strong>
                <strong className="text-xl  text-bold text-gray-700 flex justify-center items-center gap-1 font-semibold">
                  <span>
                    <IoWomanOutline className="text-primary  text-bold text-[24px]" />
                  </span>
                  <span className="font-[600]">
                    {participants.filter((p) => p.gender === "Female").length}
                  </span>
                </strong>
              </div>
            )}
           <span
                className={`  ${
                  config.children ? "text-[20px] text-bold font-Satoshi font-[500]" : "font-[500] text-[20px] font-Satoshi "
                }  ${config.textColor} `}>
                {config.primaryText}
              </span>
            </div>
            
             
               <div className="rounded-full  flex items-center justify-center ">
              {config.Icon}
            </div>
            {config.children && (
              <span className="subtitle2  text-main">
                {participants.length}
              </span>
            )}
          </BoxWrapper>
        ))}
      </div>
      <div className="w-full grid  grid-cols-1 z-0 pb-6 h-full  md:grid-cols-1 lg:grid-cols-2">
        <Chart participants={participantsAwards} />
        <div className="w-full h-full grid grid-row-2 mt-10">
          <div className="w-full grid grid-cols-1 md:grid-cols-2  p-2 gap-1">
            <AwardGainedParticipantCard barData={barData} />
            <ProjectsSummaryCard barData={barData} />
          </div>
          <div className="w-full p-2  h-full ">
            <UserMap />
          </div>
        </div>
      </div>
    </Layout>
  );
}


