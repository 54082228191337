import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"
const columns = [
  { Header: 'Center', accessor: 'awardCentre' },
  { Header: 'Award Leader', accessor: 'awardLeader' },
  { Header: 'Activity Name', accessor: 'activityName' },
  { Header: 'Upload Report', accessor: 'uploadReport' },
  { Header: 'Notes', accessor: 'notes' },
];

const AjReportingTable = ({ AjReporting, openEditModal, deleteAjReporting }) => {
  const renderRowActions = ({id}) => (
    <div className='z-30'>
    <Dropdown  
      onEdit={()=>openEditModal(id)}
      onDelete={()=>deleteAjReporting(id)}
    />
    </div>
  );

  return (
    <Table
      columns={columns}
      data={AjReporting}
      renderRowActions={renderRowActions}
    />
  );
};

export default AjReportingTable;
