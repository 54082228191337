import React, { useState, useEffect } from "react";
import axios from "axios";

const GeneralSettings = () => {
  const [settings, setSettings] = useState({
    Logo: "",
    Favicon: "",
    LegalName: "",
    Address: "",
    Email: "",
    TaxNo: "",
    Contact: "",
    Footer: ""
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedFavicon, setSelectedFavicon] = useState(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/settings`);
      setSettings(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch company settings");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleImageUpload = (e) => {
    const { name, files } = e.target;
    if (name === "Logo") {
      setSelectedLogo(files[0]);
    } else if (name === "Favicon") {
      setSelectedFavicon(files[0]);
    }
  };

  const handleSettingsUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("LegalName", settings.LegalName);
    formData.append("Address", settings.Address);
    formData.append("Email", settings.Email);
    formData.append("TaxNo", settings.TaxNo);
    formData.append("Contact", settings.Contact);
    formData.append("Footer", settings.Footer);

    if (selectedLogo) formData.append("Logo", selectedLogo);
    if (selectedFavicon) formData.append("Favicon", selectedFavicon);

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/settings`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });
      alert("Settings updated successfully");
    } catch (error) {
      console.error("Error updating settings", error);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-2xl font-Satoshi font-semibold text-white  bg-primary p-4 mb-6 rounded-lg">General Setting</h2>

      <div className="max-w-6xl mx-auto my-12 p-6 bg-white shadow-lg rounded-lg">
        {/* Display loading indicator */}
        {loading && <p>Loading settings...</p>}

        {/* Display error message */}
        {error && <p className="text-red-500">{error}</p>}

        <form onSubmit={handleSettingsUpdate} className="space-y-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Logo Upload */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Upload Logo</label>
            <input
              type="file"
              name="Logo"
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Legal Name */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Legal Name</label>
            <input
              type="text"
              name="LegalName"
              onChange={handleChange}
              value={settings.LegalName}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Address */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Address</label>
            <input
              type="text"
              name="Address"
              onChange={handleChange}
              value={settings.Address}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Email */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Email</label>
            <input
              type="email"
              name="Email"
              onChange={handleChange}
              value={settings.Email}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Tax Number */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Tax Number</label>
            <input
              type="text"
              name="TaxNo"
              onChange={handleChange}
              value={settings.TaxNo}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Contact */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Contact</label>
            <input
              type="text"
              name="Contact"
              onChange={handleChange}
              value={settings.Contact}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Footer */}
          <div className="flex flex-col justify-end gap-3">
            <label className="block text-gray-600">Footer</label>
            <textarea
              name="Footer"
              rows="3"
              onChange={handleChange}
              value={settings.Footer}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Update Settings
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GeneralSettings;
