import React from 'react';
import Table from '../common/Table';
import Dropdown from "../Dropdown/dropDown"

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; // Extracts 'YYYY-MM-DD' from the ISO string
};

const columns = [
  { Header: 'Award ID', accessor: 'awardID' },
  { Header: 'adminNumber', accessor: 'adminNumber' },
  { Header: 'Student Name', accessor: 'studentName' },
  { Header: 'Center', accessor: 'institutionName' },
  { Header: 'Level Name', accessor: 'levelName' },
  { Header: 'Start Date', accessor: 'startDate', Cell: ({ value }) => formatDate(value) },
  { Header: 'Expected End Date', accessor: 'expectedEndDate', Cell: ({ value }) => formatDate(value) },
  { Header: 'Actual End Date', accessor: 'actualEndDate', Cell: ({ value }) => formatDate(value) },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Certificate No', accessor: 'certNo' },
  { Header: 'Notes', accessor: 'notes' }
];

const AwardsTable = ({ awards, openEditModal, deleteawardsID }) => {
  const renderRowActions = ({ awardID }) => (
    <div className='z-50'>
    <Dropdown  onEdit={()=>openEditModal({awardID})} onDelete={()=>deleteawardsID({awardID})} />
  </div>
  );

  return (
    <Table
      columns={columns}
      data={awards}
      renderRowActions={renderRowActions}
    />
  );
};

export default AwardsTable;
