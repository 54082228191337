import React from 'react';
import Table from '../common/Table';
import Dropdown from '../Dropdown/dropDown';
const AjApprovalTable = ({ AjApproval, openEditModal, deleteAjApproval, openApprovalModal }) => {
  const columns = [
    { 
      Header: 'Award Center', 
      accessor: 'awardCentre',
      Cell: ({ row }) => (
        <div className="flex items-center">
          <span className="mr-2">{row.original.awardCentre}</span>
          <button
            onClick={() => openApprovalModal(row.original)}
            className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-1 px-2 rounded"
          >
            Review
          </button>
        </div>
      )
    },      
    { Header: 'Award Leader', accessor: 'awardLeader' },
    { Header: 'Region', accessor: 'region' },    
    { Header: 'Activity Name', accessor: 'activityName' },
    { Header: 'Activity Date', accessor: 'activityDate' },  
    { Header: 'Apply Date', accessor: 'applyDate' },        
    { Header: 'Assessors', accessor: 'assessors' },        
    { Header: 'No. of Participants', accessor: 'participantsNo' },
    { Header: 'Upload Form', accessor: 'uploadForm' },      
    { Header: 'Notes', accessor: 'notes' },
  ];

  const renderRowActions = (activity) => (
    <div className='z-30'>
    <Dropdown  
      onEdit={()=>openEditModal(activity)}
      onDelete={()=>deleteAjApproval(activity.id)}
    />
  </div> 
  );

  return (
    <Table
      columns={columns}
      data={AjApproval}
      renderRowActions={renderRowActions}
    />
  );
};

export default AjApprovalTable;