import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from '../../utils/splitWordFunc';
import { FiSearch } from "react-icons/fi";
import { customStyles } from "../../styles/customStyles";
import LevelsForm from "../../components/forms/participantLevelsF";
import LevelsTable from "../../components/tables/participantLevelT";
import {
  addLevels as addLevelsService,
  getLevelsById,
  updateLevels,
  deleteLevels,
  getLevels,
} from "../../services/participantLevelsS";
import Layout from "../../components/Layout/layout";

Modal.setAppElement("#root");

const AddLevels = () => {
  const [levels, setLevels] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("levelName");
  const [searchTerm, setSearchTerm] = useState('');
const [update,setUpdate] = useState(true)
  const [filteredLevels, setFilteredLevels] = useState(levels);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLevels, setNewLevels] = useState({
    levelID: "",
    levelName: "",
    duration: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedLevelsID, setSelectedLevelsID] = useState(null);

  useEffect(() => {
    const fetchedlevels = async () => {
      try {
        const fetchedLevels = await getLevels();
        setLevels(fetchedLevels);
        setFilteredLevels(fetchedLevels)
      } catch (error) {
        console.error("Error fetching levels:", error.response.data);
      }
    };

    fetchedlevels();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLevels((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewLevels((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewLevels = async () => {
    try {
      const levelsPayload = {
        levelID: String(newLevels.levelID), // Ensure LevelsID is a string
        levelName: newLevels.levelName,
        duration: newLevels.duration,
        notes: newLevels.notes,
      };

      console.log("New levels Payload:", levelsPayload);
      const addedlevels = await addLevelsService(levelsPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding levels:", error.response.data);
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to add levels: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const openAddLevelsModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewLevels({
      levelID: "",
      levelName: "",
      duration: "",
      notes: "",
    });
  };

  const openEditLevelsModal = async (levels) => {
    try {
      const fetchedLevels = await getLevelsById(String(levels.levelID));
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedLevelsID(levels.levelID);
      setNewLevels({
        ...fetchedLevels,
      });
    } catch (error) {
      console.error(
        `Error fetching levels with ID ${levels.levelID}:`,
        error.response.data
      );
    }
  };

  const updateExistingLevels = async () => {
    try {
      const levelsPayload = { ...newLevels };

      console.log("Updated levels Payload:", levelsPayload);

      const updatedLevels = await updateLevels(selectedLevelsID, levelsPayload);
    setUpdate(update=>!update)
      setIsModalOpen(false);
    } catch (error) {
      console.error(
        `Error updating levels with ID ${selectedLevelsID}:`,
        error.response.data
      );
      setErrors(error.response.data.errors || {});
      alert(
        `Failed to update levels: ${
          error.response.data.title
        }\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`
      );
    }
  };

  const deleteExistingLevels = async (levelID) => {
    try {
      await deleteLevels(levelID);
      setLevels((prev) => prev.filter((inst) => inst.levelID !== levelID));
    } catch (error) {
      console.error(
        `Error deleting levels with ID ${levelID}:`,
        error.response.data
      );
      alert(`Failed to delete levels: ${error.response.data.title}`);
    }
  };

  const closeAddLevelsModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteLevelsHandler = (levelID) => {
    if (
      window.confirm(
        `Are you sure you want to delete levels with ID ${levelID}?`
      )
    ) {
      deleteExistingLevels(levelID);
    }
  };


   //Search 
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterLevels(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterLevels(searchTerm, value);
  };

  const filterLevels = (searchTerm, column) => {
    const filtered = levels.filter(level => 
      level[column].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLevels(filtered);
  };
  
  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Levels</h1>
      <div className="p-4">
        <div className="flex justify-between">
          <button
            onClick={openAddLevelsModal}
            className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2">
            <FaPlus /> <span>Levels</span>
          </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {levels.length > 0 ? (
                Object.keys(levels[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <LevelsTable
          levels={filteredLevels}
          openEditModal={openEditLevelsModal}
          deleteLevels={deleteLevelsHandler}
        />
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeAddLevelsModal}
        contentLabel={editMode ? "Edit Levels" : "Add levels"}>
        <h2 className="subtitle2 mb-4">
          {editMode ? "Edit Levels" : "Add Levels"}
        </h2>
        <LevelsForm
          formValues={newLevels}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          errors={errors}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={editMode ? updateExistingLevels : addNewLevels}
            className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? "Update" : "Save"}
          </button>
          <button
            onClick={closeAddLevelsModal}
            className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddLevels;
