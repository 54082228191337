import apiClient from './apiClient';

const addAjApproval = async (AjApprovalData) => {
  try {
    const response = await apiClient.post('/api/ActivityApproval', AjApprovalData);
    return response.data;
  } catch (error) {
    console.error('Error in addAjApproval:', error.response.data);
    throw error;
  }
};

const getAjApprovalById = async (ID) => {
  try {
    const response = await apiClient.get(`/api/ActivityApproval/${ID}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const updateAjApproval = async (ID, updatedData) => {
  try {
    const response = await apiClient.put(`/api/ActivityApproval/${ID}`, updatedData);
    return response.data;
  } catch (error) {
    console.error(`Error updating AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const deleteAjApproval = async (ID) => {
  try {
    await apiClient.delete(`/api/ActivityApproval/${ID}`);
  } catch (error) {
    console.error(`Error deleting AjApproval with ID ${ID}:`, error.response.data);
    throw error;
  }
};

const getAjApproval = async () => {
  try {
    const response = await apiClient.get('/api/ActivityApproval');
    return response.data;
  } catch (error) {
    console.error('Error fetching AjApproval:', error.response?.data || error.message);
    throw error;
  }
};

export { 
  addAjApproval, 
  getAjApprovalById, 
  updateAjApproval, 
  deleteAjApproval, 
  getAjApproval 
};
