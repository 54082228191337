import React, { useState } from 'react';
import Table from '../common/Table';
import Dropdown from '../Dropdown/dropDown';
const ParticipantActivityTable = ({ ParticipantActivity, updateParticipantActivity, deleteParticipantActivity }) => {
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    { Header: 'Admin Number', accessor: 'adminNumber' },
    { Header: 'Student Name', accessor: 'studentName' },
    { Header: 'Award Center',
      accessor: 'awardCenter',
      Cell: ({ row }) => (
        <button
          onClick={() => {
            setModalData(row.original); // Pass the row data to the modal
            setShowModal(true); // Open modal
          }}
          className="bg-blue-500 text-white p-1 rounded"
        >
          {row.original.awardCenter}
        </button>
      ), },
    { Header: 'Award Level', accessor: 'awardLevel' },
    { Header: 'Activity Name', accessor: 'activityName' },
    { Header: 'Medical Form', accessor: 'medicalForm' },
  ];

  const renderRowActions = (activity) => (
    <div className='z-30'>
    <Dropdown  
      onEdit={()=>updateParticipantActivity(activity)}
      onDelete={()=>deleteParticipantActivity(activity.id)}
    />
  </div>
  );

  return (
    <div>
      <Table
        columns={columns}
        data={ParticipantActivity}
        renderRowActions={renderRowActions}
      />

    </div>
  );
};

export default ParticipantActivityTable;
