
import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoSettingsSharp, IoChevronDownOutline, IoClose } from "react-icons/io5";
import {filterMenuItemsByPermissions } from "../../utils/dropdownFilterFunction"
import { AnimatedSidebar, AnimatedMenuItem, AnimatedIcon, AnimatedDropdown, AnimatedArrow, SelectedItem,  SelectedDropdownItem, AnimatedDropdownItem } from './sidebarAnimations';

import {
  FaHome,
  FaCalendarAlt,
  FaBuilding,
  FaUser,
  FaProjectDiagram,
  FaCogs,
    FaLayerGroup,

} from "react-icons/fa";
import { MdModelTraining } from "react-icons/md";
import { GiReceiveMoney, GiArchiveResearch } from "react-icons/gi";
import { TbFileReport } from "react-icons/tb";

const Sidebar = ({ isOpen, onClose }) => {
  const [currentUser,setCurrentUser] = useState({})
  const [dropdown, setDropdown] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
 

  useEffect(() => {
    // Find the current path in the menu items and set the selected items
    menuItems.forEach(item => {
      if (item.path === location.pathname) {
        setSelectedItem(item.name);
        setSelectedDropdownItem(null);
      } else if (item.dropdown) {
        const dropdownItem = item.dropdown.find(d => d.path === location.pathname);
        if (dropdownItem) {
          setSelectedItem(item.name);
          setSelectedDropdownItem(dropdownItem.name);
          setDropdown(item);
        }
      }
    });
  }, [location.pathname]);

  const handleItemClick = (item) => {
    if (item.path) {
      navigate(item.path);
      setSelectedItem(item.name);
      setSelectedDropdownItem(null);
    }
    setDropdown(prevDropdown => 
      prevDropdown.name === item.name ? {} : item
    );
  };

  const handleDropdownItemClick = (item, dropdownItem) => {
    navigate(dropdownItem.path);
    setSelectedItem(item.name);
    setSelectedDropdownItem(dropdownItem.name);
  };
  const hasPermission = (permissionName) => {
    return currentUser.user?.role?.permissions?.some(
      (perm) => perm.name === permissionName
    );
  };

  const menuItems = [
    { name: "Dashboard", path: "/dashboard", icon: <FaHome />,permission: "Access to Dashboard" },
    { name: "Calendar", path: "/calendar", icon: <FaCalendarAlt />,permission: "Access to calendar" },
    {
      name: "Award Centers",
      icon: <FaBuilding />,
      permission: "Access to center",
      path: "",
      dropdown: [
        { name: "Award Center", path: "/institutions/addInstitutions",permission: "Access to center" },
        { name: "Recruitment Stages", path: "/institutions/institutionStages",permission: "Access to stages" },
        { name: "Payment Status", path: "/institutions/institutionStatus" ,permission: "Access to status" },
        { name: "Award Center Type", path: "/institutions/institutionTypes",permission: "Access to type" },
      ],
    },
    {
      name: "Participants",
      path: "",
      permission: "Access to participants",
      icon: <FaUser />,
      dropdown: [
        { name: "Participant", path: "/participants/participant",permission: "Access to participants"
        },
        { name: "Levels", path: "/participants/participantLevels",permission: "Access to levels" },
        { name: "Participants Award", path: "/participants/participant-award",permission: "Access to awards" },
      ],
    },
    {
      name: "Projects",
      path: "",
      permission: "Access to projects",
      icon: <FaProjectDiagram />,
      dropdown: [
        { name: "Projects", path: "/project/project", permission: "Access to projects",
        },
        { name: "Status", path: "/project/projectStatus",permission: "Access to project status" },
        { name: "Testimonials", path: "/project/testimonials",permission: "Access to project testimonials" },
      ],
    },
    {
      name: "Adventurous Journey",
       permission: "Access to Adventurous Journey",
      path: "",
      icon: <FaProjectDiagram />,
      dropdown: [
        { name: "AJ's Approval", path: "/ajs/ajapproval",permission: "Access to Adventurous Journey", }, 
        { name: "AJs", path: "/participants/participantActivities",permission: "Access to Adventurous Journey activities", },
        { name: "AJ's Reporting", path: "/ajs/ajreporting",permission: "Access to Adventurous Journey reportings", },
     
      ],
    },  
    {
      name: "Program",
      permission: "Access to programs",
      path: "",
      icon: <FaCogs />,
      dropdown: [{ name: "Program", path: "/program/program",permission: "Access to programs", }],
    },
    {
      name: "Adult Helpers",
      path: "",
      permission: "Access to Adult Helpers",
      icon: <FaUser />,
      dropdown: [
        { name: "Adult Helper", path: "/helpers/helper",permission: "Access to Adult Helpers" },
        { name: "Helper Type", path: "/helpers/helperType",permission: "Access to Helpers types" },
      ],
    },
    {
      name: "Training",
      path: "",
      permission: "Access to Training",
      icon: <MdModelTraining />,
      dropdown: [
        { name: "Training", path: "/training/training",permission: "Access to Training" },
        { name: "Training Level", path: "/training/trainingLevel",permission: "Access to Training level" },
        { name: "Training Category", path: "/training/trainingCategory",permission: "Access to Training category" },
        { name: "Training Type", path: "/training/trainingType",permission: "Access to Training types" },
      ],
    },
    {
      name: "Financials",
      path: "",
      permission: "Access to Financial",
      icon: <GiReceiveMoney />,
      dropdown: [
        { name: "Budget", path: "/financials/budget",permission: "Access to Financial" },
        { name: "Funding Type", path: "/financials/fundingType",permission: "Access to funding type" },
        { name: "Donors", path: "/project/donors",permission: "Access to donors" }
      ],
    },
    {
      name: "Partnership",
      icon: <FaLayerGroup />,
      permission: "Access to Partnership",
      path: "",
      dropdown: [
        { name: "Partners", path: "/partnership/partnership",permission: "Access to Partnership", },
        { name: "Partner Type", path: "/partnership/partnertype",permission: "Access to Partner Type" },
      ],
    },
    {
      name: "Research",
      path: "",
      permission: "Access to Partnership",
      icon: <GiArchiveResearch />,
      dropdown: [{ name: "Feedback", path: "/research/feedback",permission: "Access to feedback", }],
    },
    {
      permission: "Access to Reports",
      name: "Reports",
      path: "",
      icon: <TbFileReport />,
      dropdown: [{ name: "Award Centers", path: "/reports/AwardCentersR",permission: "Access to Center report" },
                  { name: "Participants", path: "/reports/ParticipantsR",permission: "Access to participants report" },
                  { name: "Projects", path: "/reports/ProjectsR",permission: "Access to projects report" },
                  { name: "Aj's", path: "/reports/AjR",permission: "Access to Ajs report"  },
                  { name: "Trainings", path: "/reports/TrainingsR",permission: "Access to Trainings report" },
                  { name: "Programs", path: "/reports/ProgramsR",permission: "Access to programs report" },

                ],
    },
    {
      permission: "Access to settings",
      name: "Settings",
      path: "/settings/company",
      icon: <IoSettingsSharp />,

    },
  ];

  useEffect(()=>{
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    setCurrentUser(user)
  },[])

  const filteredMenuItems = filterMenuItemsByPermissions(currentUser.user?.role.permissions,menuItems)
  const localItem = localStorage.getItem("item");

  return (
    <AnimatedSidebar
    className={`fixed bg-white p-1 rounded-md overflow-y-auto z-[101] md:z-[101] md:relative md:p-2 inset-y-0 left-0 transform md:translate-x-0 top-[10px] ${
      isOpen ? "translate-x-0" : "-translate-x-full"
    } transition-transform duration-300 ease-in-out shadow-lg z-[101] md:z-auto w-64 md:w-auto`}
  >
   
      <div className="flex justify-between items-center p-2">
        <h2 className="text-lg font-semibold tracking-wider"></h2>
        <IoClose
          type="button"
          className="text-main block tracking-normal text-[18px] md:hidden"
          onClick={onClose}
        />
      </div>
      <ul>
        {filteredMenuItems.map((item) => (
          <AnimatedMenuItem key={item.name}>
            {selectedItem === item.name ? (
              <SelectedItem>
                <button
                  onClick={() => handleItemClick(item)}
                  className="w-full p-2 flex items-center"
                >
                  <AnimatedIcon
                    className={location.pathname === item.path ? "mr-2 text-primary" : "mr-2"}
                  >
                    {item.icon}
                  </AnimatedIcon>
                  <span className="text-primary tracking-wider font-[900]">{item.name}</span>
                  {item.dropdown && (
                    <AnimatedArrow isOpen={dropdown.name === item.name} className="ml-auto">
                      <IoChevronDownOutline />
                    </AnimatedArrow>
                  )}
                </button>
              </SelectedItem>
            ) : (
              <button
                onClick={() => handleItemClick(item)}
                className="w-full p-2 flex items-center"
              >
                <AnimatedIcon
                  className={location.pathname === item.path ? "mr-2 text-primary" : "mr-2"}
                >
                  {item.icon}
                </AnimatedIcon>
                <span
                  className={
                    location.pathname === item.path
                      ? "text-primary  font-[700]"
                      : "font-[700] tracking-wider text-1xl mr-3 text-main"
                  }
                >
                  {item.name}
                </span>
                {item.dropdown && (
                  <AnimatedArrow isOpen={dropdown.name === item.name} className="ml-auto">
                    <IoChevronDownOutline />
                  </AnimatedArrow>
                )}
              </button>
            )}

            <AnimatedDropdown isOpen={dropdown.name === item.name}>
              <ul>
                {item.dropdown?.map((d) => (
                  <li key={d.path}>
                    {selectedDropdownItem === d.name ? (
                      <SelectedDropdownItem>
                        <button
                          onClick={() => handleDropdownItemClick(item, d)}
                          className="w-full ml-6 p-2 flex items-center"
                        >
                          <span className="text-primary tracking-normal font-[700] flex items-center gap-3">
                            {d.name}
                          </span>
                        </button>
                      </SelectedDropdownItem>
                    ) : (
                      <AnimatedDropdownItem>
                        <button
                          onClick={() => handleDropdownItemClick(item, d)}
                          className="w-full ml-6 p-2 flex items-center"
                        >
                          <span className="body1 text-main tracking-normal mr-3">
                            {d.name}
                          </span>
                        </button>
                      </AnimatedDropdownItem>
                    )}
                  </li>
                ))}
              </ul>
            </AnimatedDropdown>
          </AnimatedMenuItem>
        ))}
      </ul>
{/* Version number text 
 Semantic Versioning (SemVer): 
    MAJOR: Increment for incompatible changes.
    MINOR: Increment for adding functionality in a backward-compatible manner.
    PATCH: Increment for backward-compatible bug fixes.

 */}
      <div className="text-center text-2xl text-bold mt-12 text-gray-500 text-base">
        Version: 1.02.02
      </div>
    
    </AnimatedSidebar>
  );
};

export default Sidebar;

 
 