import React from "react";
import Button from "../../components/common/Button/Button";
import { useNavigate } from "react-router-dom";
const LLCSetupPage = () => {
const navigate= useNavigate()

    const handleLogout = ()=>{
        localStorage.removeItem("user")
        localStorage.removeItem("token")
        localStorage.removeItem("tokenExpiry")
        navigate("/")
    }
  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center ">
      {/* Top Section with Logo and Illustration */}
      <div className="text-center mb-8">
        <div className="ml-auto flex justify-center items-center mr-auto mb-8">
          <img
            src="/assets/newlogo.png"
            alt="Logo"
            className="h-auto w-[60%]"
          />
        </div>
        <h1 className="text-4xl font-bold mb-4 text-primary">
          Setting up your profile on President Awards Kenya
        </h1>
        <p className="text-lg text-secondary">
          Your profile setup is pending approval. Once approved, you'll be able
          to access the full project management dashboard and features.
        </p>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-10 space-y-6 md:space-y-0 text-center">
        <Button onClick={handleLogout} className="bg-primary text-white w-full">logout</Button>
      </div>
    </div>
  );
};

export default LLCSetupPage;
