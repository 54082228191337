import React from 'react';
const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 w-full bg-white shadow-md text-main px-6 py-4 flex flex-col items-center justify-center z-50">
      
      {/* Horizontal Divider */}
      <hr class="w-1/2 border-gray-300 mx-auto mb-2" />

      {/* Footer Text */}
      <p className="text-center text-sm font-satoshi font-medium md:text-base">
        ©{new Date().getFullYear()} President's Award Kenya. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
