import React, { useState, useEffect } from 'react';
import Input from '../common/Input';
import styles from '../../styles/modal.module.css';
import { getInstitutions } from '../../services/awardCenterS'
import { getParticipantActivity} from '../../services/ajS'

const AjReportingForm = ({ formValues, setFormValues, handleInputChange, errors }) => {
  const [institutions, setInstitutions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);


  //Fetch Institutions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedInstitutions = await getInstitutions();
        setInstitutions(fetchedInstitutions);
      } catch (error) {
        console.error('Error fetching institutions:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  //Fetch activites undergone
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedActivities = await getParticipantActivity();
        setActivities(fetchedActivities);
      } catch (error) {
        console.error('Error fetching Activities:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <form className={styles.form}>
      <div className="space-y-4">
        <div>
          <label htmlFor="awardCentre">Award Center:</label>
          {loading ? (
            <p>Loading award centers...</p>
          ) : (
            <select
              name="awardCentre"
              value={formValues.awardCentre}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Award Center</option>
              {institutions.map((institution) => (
                <option key={institution.institutionID} value={institution.awardCentre}>
                  {institution.institutionName}
                </option>
              ))}
            </select>
          )}
          {errors.awardCentre && <p className="text-red-500">{errors.institutionID[0]}</p>}
        </div>

        <div>
          <label htmlFor="awardLeader">Award Leader:</label>
          <Input
            name="awardLeader"
            placeholder="Award Leader"
            value={formValues.awardLeader}
            onChange={handleInputChange}
          />
          {errors.awardLeader && <p className="text-red-500">{errors.awardLeader[0]}</p>}
        </div>

        <div>
          <label htmlFor="activityName">Activity Name:</label>
          {loading ? (
            <p>Loading activities...</p>
          ) : (
            <select
              name="activityName"
              value={formValues.activityName}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select Activity</option>
              {activities.map((activities) => (
                <option key={activities.id} value={activities.activityName}>
                  {activities.activityName}
                </option>
              ))}
            </select>
          )}
          {errors.awardCentre && <p className="text-red-500">{errors.institutionID[0]}</p>}
        </div>

        <div>
          <label htmlFor="uploadReport">Upload Form:</label>
          <Input
            type="file"
            name="uploadReport"
            onChange={handleInputChange}
          />
          {errors.uploadReport && <p className="text-red-500">{errors.uploadReport[0]}</p>}
        </div>

        <div>
          <label htmlFor="notes">Notes:</label>
          <Input
            name="notes"
            placeholder="Notes"
            value={formValues.notes}
            onChange={handleInputChange}
          />
          {errors.notes && <p className="text-red-500">{errors.notes[0]}</p>}
        </div>
      </div>
    </form>
  );
};

export default AjReportingForm;
