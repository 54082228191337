import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import ParticipantActivityForm from '../../components/forms/ajsF';
import ParticipantActivityTable from '../../components/tables/aJsT';
import { addParticipantActivity, getParticipantActivityById, updateParticipantActivity, deleteParticipantActivity, getParticipantActivity } from '../../services/ajS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddParticipantActivity = () => {
  const [ParticipantActivity, setParticipantActivity] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update,setUpdate] = useState(false)
  const [newParticipantActivity, setNewParticipantActivity] = useState({ 
   
    adminNumber: '',
    studentName: '',
    awardLevel: '',    
    awardCenter: '',
    activityName: '',
    medicalForm: '',
    notes: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredParticipantActivity, setFilteredParticipantActivity] = useState(ParticipantActivity);
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    const fetchParticipantActivity = async () => {
      try {
        const fetchedParticipantActivity = await getParticipantActivity();
        setParticipantActivity(fetchedParticipantActivity);
        setFilteredParticipantActivity(fetchedParticipantActivity);
      } catch (error) {
        console.error('Error fetching ParticipantActivity:', error.response?.data);
      }
    };

    fetchParticipantActivity();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewParticipantActivity((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewParticipantActivity = async () => {
    try {
      const ParticipantActivityPayload = { ...newParticipantActivity };
      const addedParticipantActivity = await addParticipantActivity(ParticipantActivityPayload);
      setParticipantActivity((prev) => [...prev, addedParticipantActivity]);
      setFilteredParticipantActivity(prev => [...prev, addedParticipantActivity]);
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error('Error adding Participant Activity:', error.response?.data);
      setErrors(error.response?.data.errors || {});
      alert(`Failed to add Participant Activity: ${error.response?.data.title}\nDetails: ${JSON.stringify(error.response?.data.errors, null, 2)}`);
    }
  };

  const openAddParticipantActivityModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewParticipantActivity({
       
      adminNumber: '',
      studentName: '',
      awardLevel: '',    
      awardCenter: '',
      activityName: '',
      medicalForm: '',
      notes: '',
    });
  };

  const openEditParticipantActivityModal = async (ParticipantActivity) => {
    try {
      const fetchedParticipantActivity = await getParticipantActivityById(ParticipantActivity.id);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedID(ParticipantActivity.id);
      setNewParticipantActivity({
        ...fetchedParticipantActivity,
      });
    } catch (error) {
      console.error(`Error fetching ParticipantActivity with ID ${ParticipantActivity.id}:`, error.response?.data);
    }
  };
  

  const updateExistingParticipantActivity = async (ID) => {
    try {
      const ParticipantActivityPayload = { ...newParticipantActivity };
      const updatedParticipantActivity = await updateParticipantActivity(selectedID, ParticipantActivityPayload);
      setParticipantActivity((prev) => prev.map((act) => (act.id === selectedID ? updatedParticipantActivity : act)));
      setFilteredParticipantActivity(prev => prev.map(act => act.id === selectedID ? updatedParticipantActivity : act));
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating ParticipantActivity with ID ${selectedID}:`, error.response?.data);
      setErrors(error.response?.data.errors || {});
      alert(`Failed to update ParticipantActivity: ${error.response?.data.title}\nDetails: ${JSON.stringify(error.response?.data.errors, null, 2)}`);
    }
  };

  const deleteExistingParticipantActivity = async (ID) => {
    try {
      await deleteParticipantActivity(ID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting ParticipantActivity with ID ${ID}:`, error.response?.data);
      alert(`Failed to delete ParticipantActivity: ${error.response?.data.title}`);
    }
  };

  const closeAddParticipantActivityModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteParticipantActivityHandler = (ID) => {
    if (window.confirm(`Are you sure you want to delete ParticipantActivity with ID ${ID}?`)) {
      deleteExistingParticipantActivity(ID);
    }
  };
   //Search
   const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterParticipantActivity(value, selectedColumn);
  };
  const handleFilterParameterChange = (event) => {
    const value = event.target.value;
    setSelectedColumn(value);
    filterParticipantActivity(searchTerm, value);
  };

  const filterParticipantActivity = (searchTerm, column) => {
    const filtered = ParticipantActivity.filter((activity) =>
      activity[column] 
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredParticipantActivity(filtered);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4"> Adventurous Journey</h1>
      <div className="p-4">
       

        <div className='flex justify-between'>
        <button
          onClick={openAddParticipantActivityModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
             <FaPlus /><span>Activity</span>  
        </button>

        <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
              <select className='p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md' value={selectedColumn} onChange={handleFilterParameterChange}>
                {ParticipantActivity.length > 0 ? Object.keys(ParticipantActivity[0])?.map(op=><option value={op}>{splitWordFunc(op)}</option>) : <option>No filter property</option>}
              </select>
              <input
                placeholder="search"
                onChange={handleSearchChange}
                className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
              />
              <button className="text-[20px]">
                <FiSearch />
              </button>
            </form>
        </div>
        <ParticipantActivityTable
          ParticipantActivity={filteredParticipantActivity}
          updateParticipantActivity={openEditParticipantActivityModal}
          deleteParticipantActivity={deleteParticipantActivityHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddParticipantActivityModal} contentLabel={editMode ? "Edit ParticipantActivity" : "Add ParticipantActivity"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit Activity' : 'Add Activity'}</h2>
        <ParticipantActivityForm
          formValues={newParticipantActivity}
          setFormValues={setNewParticipantActivity}
          handleInputChange={handleInputChange}
          handleSubmit={editMode ? updateExistingParticipantActivity : addNewParticipantActivity}
          errors={errors}
        />
         <button onClick={editMode ? updateExistingParticipantActivity : addNewParticipantActivity} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
        <button onClick={closeAddParticipantActivityModal} className="btn btn-secondary mt-4">Close</button>
      </Modal>
    </Layout>
  );
};

export default AddParticipantActivity;
