import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import PartnershipForm from '../../components/forms/partnershipF';
import PartnershipTable from '../../components/tables/partnershipT';
import { addPartnership as addPartnershipService, getPartnershipById, updatePartnership, deletePartnership, getPartnership } from '../../services/partnershipS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddPartnership = () => {
  const [partnerships, setPartnerships] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPartnership, setNewPartnership] = useState({ 
      partnerID: '',
      partnerName: '',
      partnerEmail: '',
      phoneNo: '',
      partnerType: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredPartnerships, setFilteredPartnerships] = useState(partnerships);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedPartnershipId, setSelectedPartnershipId] = useState(null);

  useEffect(() => {
    const fetchPartnerships = async () => {
      try {
        const fetchedPartnerships = await getPartnership();
        setPartnerships(fetchedPartnerships);
        setFilteredPartnerships(fetchedPartnerships); // Initialize filtered participants

      } catch (error) {
        console.error('Error fetching partnerships:', error.response.data);
      }
    };

    fetchPartnerships();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPartnership((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewPartnership = async () => {
    try {
      const partnershipPayload = { ...newPartnership };
      await addPartnershipService(partnershipPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error('Error adding partnership:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add partnership: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddPartnershipModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewPartnership({
      partnerID: '',
      partnerName: '',
      partnerEmail: '',
      phoneNo: '',
      partnerType: '',
    });
  };

  const openEditPartnershipModal = async (partnerID) => {
    try {
      const fetchedPartnership = await getPartnershipById(partnerID);
    setUpdate(prev=>!prev)
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedPartnershipId(partnerID);
      setNewPartnership({
        ...fetchedPartnership,
      });
    } catch (error) {
      console.error(`Error fetching partnership with ID ${partnerID}:`, error.response.data);
    }
  };

  const updateExistingPartnership = async () => {
    try {
      const partnershipPayload = { ...newPartnership };

      console.log('Updated Partnership Payload:', partnershipPayload);

      await updatePartnership(selectedPartnershipId, partnershipPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating partnership with ID ${selectedPartnershipId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update partnership: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingPartnership = async (partnerID) => {
    try {
      await deletePartnership(partnerID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting partnership with ID ${partnerID}:`, error.response.data);
      alert(`Failed to delete partnership: ${error.response.data.title}`);
    }
  };

  const closeAddPartnershipModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deletePartnershipHandler = (partnerID) => {
    if (window.confirm(`Are you sure you want to delete partnership with ID ${partnerID}?`)) {
      deleteExistingPartnership(partnerID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterPartnerships(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterPartnerships(searchTerm, value);
    };
  
    const filterPartnerships = (searchTerm, column) => {
      const filtered = partnerships.filter((partnership) =>
        partnership[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredPartnerships(filtered);
    };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Partnerships</h1>
      <div className="p-4">
        
        <div className="flex justify-between">
        <button
          onClick={openAddPartnershipModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
             <FaPlus /><span>Partnership</span>  
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {partnerships.length > 0 ? (
                Object.keys(partnerships[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <PartnershipTable
          partnerships={filteredPartnerships}
          openEditModal={openEditPartnershipModal}
          deletePartnership={deletePartnershipHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddPartnershipModal} contentLabel={editMode ? "Edit Partnership" : "Add Partnership"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit Partnership' : 'Add Partnership'}</h2>
        <PartnershipForm 
          formValues={newPartnership} 
          handleInputChange={handleInputChange} 
          errors={errors} 
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingPartnership : addNewPartnership} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddPartnershipModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddPartnership;
