import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaPlus } from "react-icons/fa6";
import { splitWordFunc } from "../../utils/splitWordFunc";
import { FiSearch } from "react-icons/fi";
import { customStyles } from '../../styles/customStyles';
import BudgetForm from '../../components/forms/budgetF';
import BudgetTable from '../../components/tables/budgetT';
import { addBudget as addBudgetService, getBudgetById, updateBudget, deleteBudget, getBudgets } from '../../services/budgetS';
import Layout from '../../components/Layout/layout';

Modal.setAppElement('#root');

const AddBudget = () => {
  const [budget, setBudgets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBudget, setNewBudget] = useState({
    projectID: '',
    projectName: '',
    coordinator: '',
    startDate: '',
    endDate: '',
    description: '',
    cost: '',
    institutionName: '',
    subCounty: '',
    county: '',
  });
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [filteredBudgets, setFilteredBudgets] = useState(budget);
  const [searchTerm, setSearchTerm] = useState("");
  const [update,setUpdate] = useState(false)
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        const fetchedBudgets = await getBudgets();
        setBudgets(fetchedBudgets);
        setFilteredBudgets(fetchedBudgets);
      } catch (error) {
        console.error('Error fetching Budget:', error.response.data);
      }
    };

    fetchBudgets();
  }, [update]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBudget((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewBudget((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addNewBudget = async () => {
    try {
      const budgetPayload = { ...newBudget };
      await addBudgetService(budgetPayload);
      setIsModalOpen(false);
      setUpdate(prev=>!prev)
      setErrors({});
    } catch (error) {
      console.error('Error adding budget:', error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to add budget: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const openAddBudgetModal = () => {
    setEditMode(false);
    setIsModalOpen(true);
    setNewBudget({
        projectID: '',
        projectName: '',
        coordinator: '',
        startDate: '',
        endDate: '',
        description: '',
        cost: '',
        institutionName: '',
        subCounty: '',
        county: '',
    });
  };

  const openEditBudgetModal = async (projectID) => {
    try {
      console.log('Fetching budget with ID:', projectID);
      const fetchedBudget = await getBudgetById(projectID);
      console.log('Fetched Budget:', fetchedBudget);
      setEditMode(true);
      setIsModalOpen(true);
      setSelectedBudgetId(projectID);
      setNewBudget({
        ...fetchedBudget,
        startDate: fetchedBudget.startDate,
        endDate: fetchedBudget.endDate,
      });
    } catch (error) {
      console.error(`Error fetching budget with ID ${projectID}:`, error.response.data);
    }
  };
  
  const updateExistingBudget = async () => {
    try {
      const budgetPayload = { ...newBudget };
      await updateBudget(selectedBudgetId, budgetPayload);
      setUpdate(prev=>!prev)
      setIsModalOpen(false);
      setErrors({});
    } catch (error) {
      console.error(`Error updating budget with ID ${selectedBudgetId}:`, error.response.data);
      setErrors(error.response.data.errors || {});
      alert(`Failed to update budget: ${error.response.data.title}\nDetails: ${JSON.stringify(error.response.data.errors, null, 2)}`);
    }
  };

  const deleteExistingBudget = async (projectID) => {
    try {
      await deleteBudget(projectID);
      setUpdate(prev=>!prev)
    } catch (error) {
      console.error(`Error deleting budget with ID ${projectID}:`, error.response.data);
      alert(`Failed to delete budget: ${error.response.data.title}`);
    }
  };

  const closeAddBudgetModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setErrors({});
  };

  const deleteBudgetHandler = (projectID) => {
    if (window.confirm(`Are you sure you want to delete budget with ID ${projectID}?`)) {
      deleteExistingBudget(projectID);
    }
  };

    //Search
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      filterBudgets(value, selectedColumn);
    };
    const handleFilterParameterChange = (event) => {
      const value = event.target.value;
      setSelectedColumn(value);
      filterBudgets(searchTerm, value);
    };
  
    const filterBudgets = (searchTerm, column) => {
      const filtered = budget.filter((budget) =>
        budget[column]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredBudgets(filtered);
    };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">Budgets</h1>
      <div className="p-4">
        <div className="flex justify-between">
        <button
          onClick={openAddBudgetModal}
          className="bg-blue-500 text-white p-2 rounded mb-4 flex justify-center items-center mr-auto gap-2"
        >
        <FaPlus />  <span>Budget</span>   
        </button>
          <form className="px-2 border-2 border-greys rounded-md flex justify-center items-center gap-2 mb-4">
            <select
              className="p-3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={selectedColumn}
              onChange={handleFilterParameterChange}>
              {budget.length > 0 ? (
                Object.keys(budget[0])?.map((op) => (
                  <option key={op} value={op}>
                    {splitWordFunc(op)}
                  </option>
                ))
              ) : (
                <option>No filter property</option>
              )}
            </select>
            <input
              placeholder="search"
              onChange={handleSearchChange}
              className="rounded-sm h-10 outline outline-none focus:outline-none  px-2 my-1"
            />
            <button className="text-[20px]">
              <FiSearch />
            </button>
          </form>
        </div>
        <BudgetTable
          budget={filteredBudgets}
          openEditModal={openEditBudgetModal}
          deleteBudget={deleteBudgetHandler}
        />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen} onRequestClose={closeAddBudgetModal} contentLabel={editMode ? "Edit Budget" : "Add Budget"}>
        <h2 className="subtitle2 mb-4">{editMode ? 'Edit Budget' : 'Add Budget'}</h2>
        <BudgetForm 
          formValues={newBudget} 
          handleInputChange={handleInputChange} 
          handleDateChange={handleDateChange} 
          errors={errors} 
        />
        <div className="flex justify-end mt-4">
          <button onClick={editMode ? updateExistingBudget : addNewBudget} className="bg-primary px-5 text-white p-2 rounded mr-2">
            {editMode ? 'Update' : 'Save'}
          </button>
          <button onClick={closeAddBudgetModal} className="outline outline-1 outline-primary text-primary px-5 p-2 rounded">
            Cancel
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AddBudget;
