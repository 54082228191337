import apiClient from './apiClient';

const addPermission = async (UsersData) => {
  try {
    const response = await apiClient.post('/api/Permission', UsersData);
    return response.data;
  } catch (error) {
    console.error('Error in Permission:', error.response.data);
    throw error;
  }
};

const getPermisionById = async (id) => {
  try {
    const response = await apiClient.get(`/api/Permission/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching Permission with ID ${id}:`, error.response.data);
    throw error;
  }
};



const updatePermission = async (id, updatedData) => {
  try {
    const response = await apiClient.put(`/api/Permission/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error(`Error updating Permissions with ID ${id }:`, error.response.data);
    throw error;
  }
};

const deletePermission = async (id) => {
  try {
    await apiClient.delete(`/api/Permission/${id}`);
  } catch (error) {
    console.error(`Error deleting Permissions with ID ${id}:`, error.response.data);
    throw error;
  }
};

const getPermissions = async () => {
  try {
    const response = await apiClient.get('/api/Permission');
    return response.data;
  } catch (error) {
    console.error('Error fetching Permissions:', error.response.data);
    throw error;
  }
};

export { addPermission,getPermissions,getPermisionById,updatePermission,deletePermission };
